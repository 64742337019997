import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { UserTypes } from "../constants";

const MachineBrand = ({ typeId, token }) => {
  const [brand, setBrand] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [newBrand, setNewBrand] = useState("");
  const userType = JSON.parse(localStorage.getItem("userType"));

  useEffect(() => {
    const fetchBrandData = async () => {
      try {
        const response = await axios.get(`${API_URL}/typesmachine/${typeId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        // Assurez-vous que le serveur renvoie correctement les informations de la marque dans la réponse
        // Par exemple, si la réponse est un objet avec un champ 'brand', vous pouvez l'utiliser comme suit :
        setBrand(response.data.marque);
        setNewBrand(response.data.marque); // Pré-remplir le nouveau nom avec le nom actuel de la marque
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de la marque :", error.message);
      }
    };

    fetchBrandData();
  }, [typeId, token]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setNewBrand(brand); // Réinitialiser le nouveau nom avec le nom actuel de la marque en cas d'annulation
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.patch(
        `${API_URL}/typesmachine/${typeId}`,
        {
          marque: newBrand, // Utiliser le nouveau nom de la marque dans la requête de mise à jour
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data) {
        setEditMode(false);
        // Mettre à jour la marque affichée après la mise à jour réussie
        setBrand(newBrand);
      } else {
        console.error("Erreur lors de la mise à jour de la marque de la machine");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la marque de la machine", error);
    }
  };

  return (
    <div className="flex">
      {editMode ? (
        <div className="inline items-center">
          <input
            type="text"
            value={newBrand}
            onChange={(e) => setNewBrand(e.target.value)}
            className="border-2 border-gray-300 rounded-md p-2 mr-2 mb-4 mt-2 w-44 sm:w-56"
          />
          <button
            onClick={handleSaveClick}
            className="bg-loginBordure hover:bg-blue-900 text-white rounded-md px-4 py-2 text-base"
          >
            Valider
          </button>
          <button
            onClick={handleCancelClick}
            className="bg-red-500 hover:bg-red-700 text-white rounded-md px-4 py-2 ml-2 text-base"
          >
            Annuler
          </button>
        </div>
      ) : (
        <>
          <div className="font-bold underline mr-2 text-base">Marque : </div>
          <div className="text-sm md:text-base lg:text-xl 3xl:text-2xl">{brand}</div>
          {userType === UserTypes.SUPER || userType === UserTypes.ADMIN ? (
          <FaEdit className="cursor-pointer ml-4 hover:text-blue-500 transition-all duration-200 ease-in-out" onClick={handleEditClick} /> ) : null}
        </>
      )}
    </div>
  );
};

export default MachineBrand;
