import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export default function ChangePasswordForm({ id, rafraichir, token }) {
  const [newuser, setNewser] = useState({ mail: "", mdp: "" });
  const [messagePassword, setMessagePassword] = useState("");
  const navigate = useNavigate();

  const showMessagePopup = (message) => {
    alert(message);
  };

  const clearMessages = () => {
    setTimeout(() => {
      setMessagePassword("");
    }, 5000);
  };

  useEffect(() => {
    clearMessages();
  }, [messagePassword]);

  const HandleChange2 = (event) => {
    setNewser({
      ...newuser,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit3 = (event) => {
    event.preventDefault();

    const userId = id;
    const userMdp = newuser.mdp;

    if (userMdp.length < 8) {
      setMessagePassword(
        "ERREUR : Le mot de passe doit contenir au moins 8 caractères."
      );
      return;
    }

    axios
      .patch(
        `${API_URL}/users/${userId}`,
        {
          password: userMdp
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        if (response.status === 401) {
          showMessagePopup(
            "ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer."
          );
          localStorage.clear();
          navigate.push("/login");
        } else {
          return response.data.data;
        }
      })
      .then(
        (result) => {
          setMessagePassword("Mot de passe modifié");
          rafraichir();
          window.location.reload();
        },
        (error) => {
          console.log(error.message);
        }
      );
  };

  const getMessageClass = () => {
    if (messagePassword === "Mot de passe modifié") {
      return "bg-green-500";
    }
    return "bg-red-500";
  };

  return (
    <form onSubmit={handleSubmit3} className="form">
      <label className="flex flex-col">
        <input
          className="text-sm md:text-base lg:text-xl 3xl:text-2xl border-4 border-loginBordure rounded-3xl p-4 text-center mt-4 mb-4"
          onChange={HandleChange2}
          value={newuser.password}
          placeholder="Nouveau mot de passe"
          name="mdp"
          type="password"
        />
      </label>
      <div className="text-center">
        <input
          type="submit"
          value="Changer le mot de passe"
          className="bg-loginBordure hover:bg-blue-900 text-white font-semibold hover:text-white hover:font-bold text-sm 3xl:text-xl border-none rounded-full p-2 w-11/12 lg:w-60 mr-auto ml-auto cursor-pointer"
        />
        {messagePassword && (
          <span
            id="message"
            className={`${getMessageClass()} rounded-lg text-white mx-auto p-2 text-center my-4 text-xl flex items-center justify-center`}
          >
            {messagePassword}
          </span>
        )}
      </div>
    </form>
  );
}
