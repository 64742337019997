import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { API_URL } from "../constants";
import { UserTypes } from "../constants";

const MachineName = ({ name, machineId, token }) => {
  const [editMode, setEditMode] = useState(false);
  const [newName, setNewName] = useState(name);
  const userType = JSON.parse(localStorage.getItem("userType"));

  const handleInputChange = (event) => {
    setNewName(event.target.value);
    event.stopPropagation();
  };

  const handleSaveClick = async () => {
    try {
      const response = await fetch(`${API_URL}/machines/${machineId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          nom: newName,
        }),
      });

      if (response.ok) {
        setEditMode(false);
        // Mettre à jour le nom localement
        setNewName(newName);
      } else {
        console.error("Erreur lors de la mise à jour du nom de la machine");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du nom de la machine", error);
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  return (
    <div>
      <h4 className="text-xl 3xl:text-3xl text-underline">
        <div className="flex items-center justify-center">
          {editMode ? (
            // Afficher le champ d'édition si editMode est true
            <div className="inline items-center mb-4">
              <input
                type="text"
                value={newName}
                onChange={handleInputChange}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                className="border-2 border-gray-300 rounded-md p-2 mr-2 mb-4"
              />
              <button
                onClick={(event) => {
                  handleSaveClick();
                }}
                className="bg-loginBordure hover:bg-blue-900 text-white rounded-md px-4 py-2"
              >
                Modifier
              </button>
              <button
              onClick={handleCancelClick}
              className="bg-red-500 hover:bg-red-700 text-white rounded-md px-4 py-2 ml-2"
            >
              Annuler
            </button>
            </div>
          ) : (
            // Sinon, afficher le nom de la machine avec l'icône d'édition (uniquement pour les utilisateurs de type "SUPER" ou "ADMIN")
            <div className="inline-flex items-center text-xl lg:text-5xl 3xl:text-6xl mb-4 font-bold text-loginBordure text-center">
              {newName}{" "}
              {userType === UserTypes.SUPER || userType === UserTypes.ADMIN ? (
                <FaEdit
                  className="cursor-pointer text-2xl 3xl:text-4xl ml-4 hover:text-blue-900 transition-all duration-200 ease-in-out"
                  onClick={() => {
                    setEditMode(true);
                  }}
                />
              ) : null}
            </div>
          )}
        </div>
      </h4>
    </div>
  );
};

export default MachineName;
