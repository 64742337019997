import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import { useNavigate } from "react-router-dom";
import Modal from "../Modal/modal";
import { FaFileExcel } from "react-icons/fa";

export default function ForgotPassword(props) {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : props.user;
  // const token = user.auth_token;
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const navigate = useNavigate();

  const clearMessages = () => {
    setTimeout(() => {
      setMessage("");
      if (messageType === "success") {
      props.hide(); 
      }
    }, 3000);
  };
  
  useEffect(() => {
    const interval = 4 * 60 * 60 * 1000; // 4 heures
    const intervalId = setInterval(clearLocalStorage, interval);
    return () => clearInterval(intervalId);
  }, []);  
  
  const clearLocalStorage = () => {
    localStorage.clear();
  };

  useEffect(() => {
    clearMessages();
  }, [message]);

  const handleEmailChange = (event) => {
    setMail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Effectuer une requête HTTP POST pour réinitialiser le mot de passe
      const response = await fetch(`${API_URL}/users/resetpassword`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ mail }), // Envoyer l'adresse e-mail au serveur
      });

      if (response.status === 204) {
        // Réinitialisation du mot de passe réussie
        setMessage(
          "Le mot de passe a été réinitialisé avec succès. Votre nouveau mot de passe vous a été envoyé par e-mail !"
        );
        setMessageType("success");
      } else if (response.status === 404) {
        // L'utilisateur avec cet e-mail n'existe pas
        setMessage("L'utilisateur avec cet e-mail n'existe pas.");
        setMessageType("error");
      } else {
        // Gérer d'autres erreurs ici
        setMessage(
          "Une erreur s'est produite lors de la réinitialisation du mot de passe."
        );
        setMessageType("error");
      }
    } catch (error) {
      console.error(
        "Erreur lors de la réinitialisation du mot de passe :",
        error
      );
      setMessage(
        "Une erreur s'est produite lors de la réinitialisation du mot de passe."
      );
      setMessageType("error");
    }
  };

  

  return (
    <Modal isShowing={props.isShowing} hide={props.hide} title="Mot de passe oublié">
      <form onSubmit={handleSubmit} className="flex flex-col w-full m-auto">
        <label className="flex flex-col text-xs sm:text-base 3xl:text-4xl text-left mb-1 mt-4 items-center">
          Adresse e-mail :
          <input
            type="email"
            value={mail}
            onChange={handleEmailChange}
            className="text-xs border-solid border-2 border-loginBordure rounded-2xl p-1 xl:p-2 text-center 3xl:text-4xl mt-2 ml-5 w-2/4"
          />
        </label>
        <input
          type="submit"
          value="Modifier le mot de passe"
          className="submitButton bg-orange-500 hover:bg-orange-600 text-white 3xl:text-4xl text-xs py-2 px-4 rounded-2xl mx-auto mt-5 mt-2 mb-1 cursor-pointer"
        />
        {message && (
          <p
            className={`message ${
              messageType === "success" ? "bg-green-500" : "bg-red-500"
            } text-white text-xl text-center p-2 rounded-md mt-2`}
          >
            {message}
          </p>
        )}
      </form>
    </Modal>
  );
}
