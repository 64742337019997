import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import axios from "axios";
import { FaTrash, FaEdit , FaCheckCircle } from "react-icons/fa";
import { UserTypes } from "../constants";

const MaintenanceType = ({ typeId, token, agenceId }) => {
  const [maintenanceData, setMaintenanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [maintenanceToDelete, setMaintenanceToDelete] = useState(null);
  const [editingMaintenance, setEditingMaintenance] = useState(null);
  const [newMaintenanceName, setNewMaintenanceName] = useState("");
  const [newMaintenanceDescription, setNewMaintenanceDescription] = useState("");
  const [editingDescription, setEditingDescription] = useState(null); // Nouvelle variable d'état
  const userType = JSON.parse(localStorage.getItem("userType"));
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [chefAtelierId, setChefAtelierId] = useState(null);
  const [error, setError] = useState(null);
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);
  const [maintenanceInterventionFrequencies, setMaintenanceInterventionFrequencies] = useState({});


  useEffect(() => {
    const fetchMaintenanceData = async () => {
      try {
        const response = await axios.get(`${API_URL}/maintenances/types/${typeId.id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setMaintenanceData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de maintenance :", error.message);
        setLoading(false);
      }
    };

    fetchMaintenanceData();
  }, [typeId, token]);

  useEffect(() => {
    const fetchChefAtelierId = async () => {
      try {
        const response = await axios.get(`${API_URL}/agences/${agenceId}/chefat`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setChefAtelierId(response.data?.id || null);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'ID du chef d'atelier :", error.message);
        setChefAtelierId(null);
      }
    };

    if (userType === UserTypes.CHEFAT) {
      fetchChefAtelierId();
    }
  }, [agenceId, token, userType]);

  const handleDeleteClick = (id) => {
    setMaintenanceToDelete(id);
    setModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!maintenanceToDelete) return;
    try {
      // Supprimer la maintenance
      await axios.delete(`${API_URL}/maintenances/${maintenanceToDelete.id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Mettre à jour localement la liste des maintenances
      setMaintenanceData((prevData) =>
        prevData.filter((maintenance) => maintenance.id !== maintenanceToDelete)
      );
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de la suppression de la maintenance :", error.message);
    } finally {
      setMaintenanceToDelete(null);
      setModalOpen(false);
    }
  };

  // Fonction pour activer l'édition du nom de la maintenance
  const handleEditMaintenanceName = (maintenance) => {
    if (userType === UserTypes.CHEFAT && chefAtelierId !== userId) {
      // Vérifier si le chef d'atelier est autorisé à modifier cette maintenance
      console.log("Vous n'êtes pas autorisé à modifier cette maintenance.");
      return;
    }
    setEditingMaintenance(maintenance);
    setNewMaintenanceName(maintenance.nom);
    setErrorMessageVisible(false);
  };

  // Fonction pour valider la modification du nom de la maintenance
  const handleSaveMaintenanceName = async () => {
    try {
      await axios.patch(
        `${API_URL}/maintenances/${editingMaintenance.id}`,
        { nom: newMaintenanceName },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Mettre à jour localement le nom de la maintenance
      setMaintenanceData((prevData) =>
        prevData.map((maintenance) =>
          maintenance.id === editingMaintenance.id
            ? { ...maintenance, nom: newMaintenanceName }
            : maintenance
        )
      );

      setEditingMaintenance(null);
      setNewMaintenanceName("");
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la modification du nom de la maintenance :", error.message);

      setErrorMessageVisible(true);
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError("Une erreur s'est produite lors de la modification du nom de la maintenance.");
      }
    }
  };
  
  

  const handleFrequencyChange = (e, maintenanceId) => {
    const { value } = e.target;
    const numericValue = parseInt(value); // ou parseFloat(value) si vous attendez des nombres à virgule flottante
    setMaintenanceInterventionFrequencies((prevFrequencies) => ({
      ...prevFrequencies,
      [maintenanceId]: numericValue,
    }));
  };
  
  

  const handleFrequencyValidation = async (maintenanceId) => {
    try {
      // Récupérer la fréquence d'intervention à partir du state
      const interventionFrequency = maintenanceInterventionFrequencies[maintenanceId];
      const numericFrequency = parseInt(interventionFrequency);
  
      // Effectuer la requête PATCH à l'API avec l'ID de la maintenance et la nouvelle valeur de la fréquence d'intervention
      await axios.patch(
        `${API_URL}/maintenances/${maintenanceId}`,
        { freq_intervention: numericFrequency,
          etat: "A_JOUR" },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la fréquence d'intervention :", error.message);
    }
  };
  
  const getCurrentDate = () => {
    const currentDate = new Date().toISOString();
    return currentDate;
  };
  
  const handleUpdateNextMaintenance = async (maintenanceId) => {
    try {
      // Effectuer une requête GET à l'API pour obtenir les détails de la maintenance
      const response = await axios.get(`${API_URL}/maintenances/types/${typeId.id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Trouver la maintenance spécifique à partir de la réponse en utilisant son ID
      const maintenanceToUpdate = response.data.data.find(maintenance => maintenance.id === maintenanceId);
  
      if (!maintenanceToUpdate) {
        console.error("Maintenance non trouvée.");
        return;
      }
  
      // Extraire la valeur de la fréquence d'intervention de la description de la maintenance
      const interventionFrequency = maintenanceToUpdate.freq_intervention;
  
      // Effectuer la requête PATCH à l'API avec l'ID de la maintenance et la nouvelle valeur de la fréquence d'intervention
      await axios.patch(
        `${API_URL}/maintenances/${maintenanceId}`,
        { heures: interventionFrequency,
          etat: "A_JOUR",
          datemaintenance: getCurrentDate() 
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
       window.location.reload();
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'échéance de la prochaine intervention de maintenance :", error.message);
    }
  };


  // Fonction pour activer l'édition de la description de la maintenance
  const handleEditMaintenanceDescription = (maintenance) => {
    if (userType === UserTypes.CHEFAT && chefAtelierId !== userId) {
      // Vérifier si le chef d'atelier est autorisé à modifier cette maintenance
      console.log("Vous n'êtes pas autorisé à modifier cette maintenance.");
      return;
    }
    setEditingDescription(maintenance); // Activer l'édition de la description uniquement
    setNewMaintenanceDescription(maintenance.description);
    setErrorMessageVisible(false);
  };

  // Fonction pour valider la modification de la description de la maintenance
  const handleSaveMaintenanceDescription = async () => {
    try {
      await axios.patch(
        `${API_URL}/maintenances/${editingDescription.id}`,
        { description: newMaintenanceDescription },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Mettre à jour localement la description de la maintenance
      setMaintenanceData((prevData) =>
        prevData.map((maintenance) =>
          maintenance.id === editingDescription.id
            ? { ...maintenance, description: newMaintenanceDescription }
            : maintenance
        )
      );

      setEditingDescription(null); // Désactiver l'édition de la description
      setNewMaintenanceDescription("");
      setError(null);
    } catch (error) {
      console.error("Erreur lors de la modification de la description de la maintenance :", error.message);

      setErrorMessageVisible(true);
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError("Une erreur s'est produite lors de la modification de la description de la maintenance.");
      }
    }
  };

  // Fonction pour annuler l'édition de la maintenance
  const handleCancelEdit = () => {
    setEditingMaintenance(null);
    setNewMaintenanceName("");
    setNewMaintenanceDescription("");
    setEditingDescription(null); // Désactiver l'édition de la description
  };

  const getMaintenanceStatusText = (etat) => {
    switch (etat) {
      case 'A_JOUR':
        return <span className="text-green-600 font-bold">À jour</span>;
      case 'A_PREVOIR':
        return <span className="text-orange-600 font-bold">À prévoir</span>;
      case 'A_FAIRE':
        return <span className="text-red-600 font-bold">À faire</span>;
      default:
        return etat;
    }
  };

  return (
    <div>
      {loading ? (
        <p>Chargement en cours...</p>
      ) : maintenanceData.length > 0 ? (
        maintenanceData.map((maintenance) => (
          <div key={maintenance.idmaint} className="border-solid border-4 border-t-0 border-r-0 border-l-0 border-loginBordure pt-4 pb-4 text-sm lg:text-xl 3xl:text-3xl mb-4">
            <div className="flex items-center mb-4">
              <h5 className="text-lg sm:text-2xl font-bold">
                {editingMaintenance && editingMaintenance.id === maintenance.id ? (
                  <>
                    <input
                      type="text"
                      className="border rounded-md px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                      value={newMaintenanceName}
                      onChange={(e) => setNewMaintenanceName(e.target.value)}
                    />
                    <button
                      className="bg-green-500 hover:bg-green-600 px-2 py-1 rounded-md text-white font-semibold text-sm ml-2"
                      onClick={handleSaveMaintenanceName}
                    >
                      Valider
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-600 px-2 py-1 rounded-md text-white font-semibold text-sm ml-2"
                      onClick={handleCancelEdit}
                    >
                      Annuler
                    </button>
                    {errorMessageVisible && error && <p className="text-red-500">{error}</p>}
                  </>
                ) : (
                  <>
                    <span className="underline text-xs lg:text-xl 3xl:text-3xl">
                      {maintenance.nom}
                    </span>
                    {userType === UserTypes.SUPER || userType === UserTypes.ADMIN || (userType === UserTypes.CHEFAT && chefAtelierId === userId) ? (
                      <button
                        className="hover:text-blue-600 transition-all duration-200 ease-in-out ml-2"
                        onClick={() => handleEditMaintenanceName(maintenance)}
                      >
                        <FaEdit />
                      </button>
                    ) : null}
                    <span className="ml-2 font-light">{getMaintenanceStatusText(maintenance.etat)}</span>
                  </>
                )}
              </h5>
            </div>
            <span className="flex text-gray-700 text-xs lg:text-xl font-bold 3xl:text-xl">
              Description :
              {editingDescription && editingDescription.id === maintenance.id ? (
                <>
                  <input
                    type="text"
                    className="border rounded-md px-2 py-1 focus:outline-none focus:ring focus:border-blue-300 ml-2"
                    value={newMaintenanceDescription}
                    onChange={(e) => setNewMaintenanceDescription(e.target.value)}
                  />
                  <button
                    className="bg-green-500 hover:bg-green-600 px-2 py-1 rounded-md text-white font-semibold text-sm ml-2"
                    onClick={handleSaveMaintenanceDescription}
                  >
                    Valider
                  </button>
                  <button
                    className="bg-red-500 hover:bg-red-600 px-2 py-1 rounded-md text-white font-semibold text-sm ml-2"
                    onClick={handleCancelEdit}
                  >
                    Annuler
                  </button>
                  {errorMessageVisible && error && <p className="text-red-500">{error}</p>}
                </>
              ) : (
                <>
                  <p className="ml-2 font-light">{maintenance.description}</p>
                  {userType === UserTypes.SUPER || userType === UserTypes.ADMIN || (userType === UserTypes.CHEFAT && chefAtelierId === userId) ? (
                    <button
                      className="hover:text-blue-600 transition-all duration-200 ease-in-out ml-2"
                      onClick={() => handleEditMaintenanceDescription(maintenance)}
                    >
                      <FaEdit />
                    </button>
                  ) : null}
                </>
              )}
            </span>
            <span className="flex text-gray-700 text-xs lg:text-xl font-bold 3xl:text-xl">
              Echéance avant la prochaine intervention :
              <p className="ml-2 font-light">{maintenance.heures}h</p>
            </span>
            <span className="flex text-gray-700 text-xs lg:text-xl font-bold 3xl:text-xl">
              Exclusive à la machine :
              <p className="ml-2 font-light text-red-600">Non</p>
            </span>
            {userType === UserTypes.SUPER || userType === UserTypes.ADMIN ? (
  <div className="">
    <div className="flex items-center mb-3 lg:mb-0 mt-2">
      <span className="text-gray-700 text-xs lg:text-xl font-bold 3xl:text-xl">
        Fréquence d'intervention :
      </span>{' '}
      <input
  className="text-xs lg:text-base 3xl:text-xl text-center border-2 border-orangeButton hover:border-bleuSTB rounded-xl p-2 w-[65%] sm:w-[50%] ml-2"
  type="number"
  value={maintenanceInterventionFrequencies[maintenance.id] || maintenance.freq_intervention || ''}
  onChange={(e) => handleFrequencyChange(e, maintenance.id)}
/>

      <span className="text-base lg:text-xl ml-2 mr-2">h</span>
      <button
  className="bg-bleuSTB 3xl:text-xl text-white text-base rounded-xl w-20 sm:w-[47%] cursor-pointer hover:bg-marronSTB ml-2"
  onClick={() => handleFrequencyValidation(maintenance.id)}
>
  Valider
</button>

    </div>
    {maintenance.datemaintenance !== null && (
      <span className="flex text-gray-700 text-xs lg:text-xl font-bold 3xl:text-xl">Dernière intervention :
        <p className="ml-2 font-light">{new Date(maintenance.datemaintenance).toLocaleDateString()}</p>
      </span>
    )}
    <div className="flex flex-col lg:flex-row items-start mt-4">
    <button
  className="flex flex-row items-center bg-green-600 hover:bg-green-700 text-white text-xs 3xl:text-xl border-none rounded-xl px-2 sm:px-3 py-1 no-underline cursor-pointer mb-3 lg:mb-0 lg:mr-2 w-21 sm:w-auto"
  onClick={() => handleUpdateNextMaintenance(maintenance.id)}
>
  <FaCheckCircle className="text-xl sm:text-2xl mr-2" />
  <span>Mettre à jour</span>
</button>

      <button
        className="text-orangeButton flex items-center hover:text-orange-600"
        onClick={() => handleDeleteClick(maintenance)}
      >
        <FaTrash className="mr-1" />
        Supprimer
      </button>
    </div>
  </div>
) : null}
          </div>
        ))
      ) : (
        <div className="border-solid border-4 border-t-0 border-r-0 border-l-0 border-loginBordure pt-4 pb-4 text-sm lg:text-xl 3xl:text-3xl mb-4">
          <p>Aucune maintenance associée à ce type de machine.</p>
        </div>
      )}
      {modalOpen && maintenanceToDelete && (
        <div className="fixed inset-0 flex items-center justify-center z-10 bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-md shadow-lg">
            <p className="text-base sm:text-lg text-center font-semibold mb-2">Confirmer la suppression de la maintenance :</p>
            <p className="text-xl text-center font-bold mb-4">{maintenanceToDelete.nom}</p>
            <div className="flex justify-center">
              <button
                className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-md text-white font-semibold mr-5"
                onClick={() => setModalOpen(false)}
              >
                Annuler
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-md text-white font-semibold "
                onClick={handleConfirmDelete}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MaintenanceType;
