import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import axios from "axios";
import { FaEdit, FaTimes } from "react-icons/fa";
import { UserTypes } from "../constants";

const MachineAssociatedType = ({ type, token, machineId, onTypeChange, navigate}) => {
  const [typeName, setTypeName] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [allTypes, setAllTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [initialType, setInitialType] = useState("");
  const userType = JSON.parse(localStorage.getItem("userType"));

  useEffect(() => {
    const fetchTypeData = async () => {
      try {
        const response = await axios.get(`${API_URL}/typesmachine`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const types = response.data.data;
        setAllTypes(types);
        console.log(types)
        const associatedType = types.find((machineType) => machineType.id === type.id);
        console.log(associatedType)
        if (associatedType) {
          setTypeName(associatedType.nom);
        }
        
        setSelectedType(type); // Mettre à jour le type sélectionné au chargement initial
        setInitialType(type); // Sauvegarder le type initial pour la fonction d'annulation
      } catch (error) {
        console.error("Erreur lors de la récupération des types de machine :", error.message);
      }
    };

    fetchTypeData();
  }, [type, token]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value); // Mettre à jour le type sélectionné lors du changement de la liste déroulante
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.patch(
        `${API_URL}/machines/${machineId.toString()}`,
        {
          typeMachine: {id:selectedType},
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data) {
        setEditMode(false);
        const updatedType = allTypes.find((typeItem) => typeItem.id === parseInt(selectedType));
        setTypeName(updatedType ? updatedType.nom : "Inconnu"); // Mettre à jour le nom du type affiché
        onTypeChange(selectedType);
        
      } else {
        console.error("Erreur lors de la mise à jour du type de machine");
      }
      
    } catch (error) {
      console.error("Erreur lors de la mise à jour du type de machine", error);
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setSelectedType(initialType);
  };
  return (
    <h4 className="pt-4 pb-4 text-xl 3xl:text-3xl text-underline">
      <div>
        {editMode ? (
          <div className="inline text-decoration-none font-light">
            <select
              value={selectedType.id}
              onChange={handleTypeChange}
              className="border-2 border-gray-300 rounded-md p-2 mr-2 w-48 mb-4"
            >
              {allTypes.map((typeItem) => (
                <option key={typeItem.id} value={typeItem.id}>
                  {typeItem.nom}
                </option>
              ))}
            </select>
            <button
              onClick={() => {handleSaveClick(); window.history.back();}}
              className="bg-loginBordure hover:bg-blue-900 text-white rounded-md px-4 py-2"
            >
              Valider
            </button>
            <button
              onClick={handleCancelClick}
              className="bg-red-500 hover:bg-red-700 text-white rounded-md px-4 py-2 ml-2"
            >
              Annuler
            </button>
          </div>
        ) : (
          <span className="inline-flex text-decoration-none font-light">
            <div className="inline-flex font-bold underline mr-2 text-xs lg:text-xl 3xl:text-3xl">Type de machine :</div>
            <div className="text-xs lg:text-xl 3xl:text-3xl">{typeName ? typeName : "Inconnu"}</div>
            {userType === UserTypes.SUPER ? (
              <FaEdit className="cursor-pointer ml-4 hover:text-blue-500 transition-all duration-200 ease-in-out" onClick={handleEditClick} />
            ) : null}
          </span>
        )}
      </div>
    </h4>
  );
};

export default MachineAssociatedType;
