import Header from "../Header/Header";
import MachineName from "./MachineName";
import MachineComment from "./MachineComment";
import MachineTime from "./MachineTime";
import MachineAgency from "../Admin/MachineAgency";
import MachineAssociatedAgency from "./MachineAssociatedAgency";
import MaintenanceFrequencySelector from "./MaintenanceFrequencySelector";
import MachineAssociatedType from "./MachineAssociatedType";
import MachineType from "../Admin/MachineType";
import MaintenanceExclu from "./MaintenanceExclu";
import MaintenanceType from "./MaintenanceType";
import MaintenanceCreation from "./MaintenanceCreation";
import CapteurName from "./CapteurName";
import Capteur from "./Capteur";
import { useState } from "react"; // Importez useState ici
import { UserTypes } from "../constants";
import { useNavigate} from "react-router-dom";
import { FaChevronDown } from 'react-icons/fa';

export default function MachineDetails(props) {
  const machine = JSON.parse(localStorage.getItem("machine"));
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.auth_token;
  const { nom, commentaire, heuresUtilisation, agence, typeMachine, id } = machine;
  const userType = JSON.parse(localStorage.getItem("userType"));
  const navigate = useNavigate();
  console.log(typeMachine)
  // État partagé pour l'agence sélectionnée
  const [selectedAgenceId, setSelectedAgenceId] = useState(agence);
  const [selectedTypeId, setSelectedTypeId] = useState(typeMachine);
  const [maintenanceType, setMaintenanceType] = useState(typeMachine);

  const handleTypeChange = (newTypeId) => {
    setMaintenanceType(newTypeId);
  };

  const [expandedMaintenance, setExpandedMaintenance] = useState(false);
  const [expandedMachineDetails, setExpandedMachineDetails] = useState(false);
  
  return (
    <div className="min-h-screen flex flex-col text-base sm:text-3xl">
      <Header user={user} />
      <div className="flex flex-auto h-full w-full justify-center text-gray-700">
        <div className="flex flex-col items-center w-full">
          {userType === UserTypes.SUPER ? (
          <div className="flex flex-col lg:flex-row w-11/12 p-4 space-y-4 lg:space-y-0 lg:space-x-4">
          <div className="border-solid border-4 border-loginBordure rounded-lg p-8 lg:w-1/2 lg:mx-auto">
              <Capteur machineId={id} token={token}/>
            </div>
          </div>
          ) : null}
          <div className="flex flex-col lg:flex-row w-11/12 p-4 space-y-4 lg:space-y-0 lg:space-x-4">
          <div className="border-solid border-4 border-loginBordure rounded-lg p-8 lg:w-1/2">
              {/* Utilisez la même approche que pour la section de maintenance */}
              <h2
                className={`items-center text-loginBordure font-bold text-3xl xl:text-5xl 3xl:text-6xl cursor-pointer mb-4 flex`}
                onClick={() => setExpandedMachineDetails(!expandedMachineDetails)}
              >
                <div className="ml-auto mr-auto"><MachineName name={nom} machineId={id} token={token} /></div>
                {window.innerWidth < 1024 && (
                  <FaChevronDown
                    className={`ml-auto mr-auto transition-transform duration-300 transform ${expandedMachineDetails ? 'rotate-180' : ''}`}
                  />
                )}
              </h2>

              {/* Contenu de la section des détails de la machine */}
              <div className={`${expandedMachineDetails ? 'block' : 'hidden'} ${window.innerWidth >= 1024 ? 'lg:block' : ''}`}>
                {/* Insérez le contenu des détails de la machine ici */}
                <MachineComment comment={commentaire} machineId={id} token={token} />
                <MachineTime time={heuresUtilisation} machineId={id} token={token} />
              <MachineAssociatedType type={typeMachine} token={token} machineId={id} onAgenceChange={setSelectedTypeId} navigate={navigate} />
              {/* Passez l'état partagé et la fonction pour le mettre à jour aux composants */}
              <MachineAssociatedAgency
                agenceId={selectedAgenceId}
                token={token}
                machineId={id}
                onAgenceChange={setSelectedAgenceId}
                onTypeChange={handleTypeChange}
              />
              <MaintenanceFrequencySelector machineId={id} token={token} />
            </div>
            </div>
            <div className="border-solid border-4 border-loginBordure rounded-lg p-8 lg:w-1/2">
          <h2
            className={`items-center text-loginBordure font-bold text-3xl xl:text-5xl 3xl:text-6xl cursor-pointer mb-4 flex`}
            onClick={() => setExpandedMaintenance(!expandedMaintenance)}
          >
            <div className="ml-auto mr-auto text-2xl">Maintenances</div>
            {window.innerWidth < 1024 && (
              <FaChevronDown
                className={`ml-auto mr-auto transition-transform duration-300 transform ${expandedMaintenance ? 'rotate-180' : ''}`}
              />
            )}
          </h2>
          <div className={`${expandedMaintenance ? 'block' : 'hidden'} ${window.innerWidth >= 1024 ? 'lg:block' : ''}`}>
            <MaintenanceExclu machineId={id} token={token} agenceId={selectedAgenceId} />
            <MaintenanceType typeId={maintenanceType} token={token} agenceId={selectedAgenceId}/>
            {userType === UserTypes.SUPER || userType === UserTypes.ADMIN || userType === UserTypes.CHEFAT ? (
              <MaintenanceCreation machineId={id} token={token} />
            ) : null}
          </div>          
        </div>           
          </div>
          
        </div>
      </div>
    </div>
  );
}
