import React from 'react';
import LoginForm from './LoginForm';
import Header from '../Header/Header';

export default function Login(props) {
  return (
    <div className="min-h-screen flex flex-col text-base sm:text-3xl">
    <Header/>
    <div className="flex flex-auto h-full items-center justify-center p-4 ">
      <LoginForm 
      setToken={props.setToken}
      setUser={props.setUser} 
      />
    </div>
    </div>
  );
}

