import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../constants";

const MaintenanceFrequencySelector = ({ machineId, token }) => {
  const storedFrequency = localStorage.getItem("maintenanceFrequency");
  const [selectedFrequency, setSelectedFrequency] = useState(storedFrequency || "weekly");
  const [heuresUtilisation, setHeuresUtilisation] = useState(null);
  const [batterie, setBatterie] = useState(null);
  const [capteurID, setCapteurID] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [newFrequency, setNewFrequency] = useState(storedFrequency || "weekly"); // Initialisez newFrequency avec la valeur stockée

  useEffect(() => {
    const fetchCapteurData = async () => {
      try {
        const response = await axios.get(`${API_URL}/machines/${machineId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 && response.data.capteur) {
          setHeuresUtilisation(response.data.heuresUtilisation);
          setBatterie(response.data.capteur.batterie);
          setCapteurID(response.data.capteur.id);
          console.log(response.data);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de capteur :", error.message);
      }
    };

    fetchCapteurData();
  }, [machineId]);

  const frequencies = [
    { value: "daily", label: "Tous les jours" },
    { value: "weekly", label: "Toutes les semaines" },
    { value: "biweekly", label: "2 fois par mois" },
    { value: "monthly", label: "1 fois par mois" },
  ];

  const handleFrequencyChange = (event) => {
    setNewFrequency(event.target.value);
  };

  const handleSendMaintenance = async () => {
    const dataToSend = {
      id: capteurID,
      batterie: batterie,
      tempsUtilisation: heuresUtilisation,
      frequency: selectedFrequency,
    };
    setSelectedFrequency(newFrequency);
    localStorage.setItem("maintenanceFrequency", newFrequency);

    try {
      await axios.post(`${API_URL}/capteurs/upload/single`, dataToSend, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setConfirmationMessage("Maintenance envoyée avec succès !");
      setErrorMessage(""); // Réinitialisez les erreurs

      // Effacez le message de confirmation après quelques secondes (par exemple, 5 secondes)
      setTimeout(() => {
        setConfirmationMessage("");
      }, 5000);
    } catch (error) {
      setErrorMessage("Erreur lors de l'envoi de maintenance : " + error.message);
      setConfirmationMessage(""); // Réinitialisez la confirmation
    }
  };

  return (
    <div className="maintenance-selector p-4 border border-gray-200 rounded-lg">
      <h3 className="text-sm lg.text-xl font-bold mb-2">
        Sélectionner la fréquence d'envoi de maintenance :
      </h3>
      <select
        value={newFrequency}
        onChange={handleFrequencyChange}
        className="block w-full border border-gray-300 rounded-lg py-2 px-4 focus:outline-none focus:border-blue-400"
      >
        {frequencies.map((freq) => (
          <option key={freq.value} value={freq.value}>
            {freq.label}
          </option>
        ))}
      </select>
      <button
        onClick={handleSendMaintenance}
        className="bg-blue-500 hover.bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg mt-2"
      >
        Envoyer la maintenance
      </button>
      {confirmationMessage && (
        <div className="text-green-500 mt-2">{confirmationMessage}</div>
      )}
      {errorMessage && <div className="text-red-500 mt-2">{errorMessage}</div>}
    </div>
  );
};

export default MaintenanceFrequencySelector;