import Error from "../Error/Error";
import { useState, useEffect} from "react";
import { useNavigate} from "react-router-dom";
import axios from 'axios';
import { API_URL } from "../constants";
import { useHistory } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";

export default function LoginForm(props) {
  const [user, setUser] = useState({ mail: "", password: "" });
  const [error, setError] = useState(null);
  const [stayConnected, setStayConnected] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false); 


  const clearMessages = () => {
    setTimeout(() => {
      setError("");
    }, 10000);
  };

  const handleCheckbox = () => setStayConnected(!stayConnected);

  useEffect(() => {
    clearMessages();
  }, [error]);

  const navigate = useNavigate();

  const handleForgotPasswordClick = () => {
    // Ouvrir le modal de réinitialisation du mot de passe lorsqu'on clique sur "Mot de passe oublié"
    setIsForgotPasswordModalOpen(true);
  };

  const closeForgotPasswordModal = () => {
    // Fermer le modal de réinitialisation du mot de passe
    setIsForgotPasswordModalOpen(false);
  };

  const handleUser = (event) =>
    setUser({ ...user, [event.target.name]: event.target.value });

    const setUserTypeInLocalStorage = (userType) => {
      localStorage.setItem("userType", JSON.stringify(userType));
    };
    const setUserIdLocalStorage = (userId) => {
      localStorage.setItem("userId", JSON.stringify(userId));
    };
    

    const handleSubmit = async (event) => {
      event.preventDefault();
    
      if (user.mail !== "" && user.password !== "") {
        try {
          const response = await axios.post(`${API_URL}/auth/login`, {
            mail: user.mail,
            password: user.password
          }, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          });
          if (response.status === 201) {
            const json = response.data;
            const auth_token = json.auth_token;
            const idUser = json.id
            localStorage.setItem("user", JSON.stringify(json));
            console.log(json);
            props.setUser(json);
    
            // Effectuer une autre requête pour récupérer la liste des utilisateurs
            const usersResponse = await axios.get(`${API_URL}/users/${idUser}`, {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + auth_token
              }
            });
    
            if (usersResponse.status === 200) {
              
                const user= usersResponse.data;
    
                setUserTypeInLocalStorage(user.type); 
                setUserIdLocalStorage(user.id); 
        
            }
    
            if (stayConnected) {
              localStorage.setItem("user", JSON.stringify(json));
            }
            navigate('/machineslist');
          } else if (response.status === 401) {
            setError("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
            localStorage.clear();
            navigate("/login");
          } else {
            setError("Une erreur s'est produite lors de la tentative de connexion.");
          }
        } catch (error) {
          console.error(error.message);
          setError("Le nom d'utilisateur ou le mot de passe est incorrect.");
        }
      } else {
        setError("Veuillez remplir tous les champs.");
      }
    };

  return (
    <div className="flex flex-col border-solid border-4 border-loginBordure rounded-2xl text-center p-12 h-auto w-11/12 sm:w-1/2">
      <h2 className="-mt-4 -mb-4 text-loginBordure font-bold text-3xl xl:text-5xl 3xl:text-6xl">Se connecter</h2>
      <form className="flex flex-col w-full m-auto mt-6 " onSubmit={handleSubmit}>
        <label className="flex flex-col text-center sm:text-center sm:mb-2 sm:-mt-1 sm:text-sm xl:text-left xl:text-3xl 3xl:text-5xl mb-4">
          E-Mail
          <input
            type="mail"
            name="mail"
            placeholder="E-Mail"
            onChange={handleUser}
            value={user.mail}
            className="text-xs border-solid border-2 border-loginBordure rounded-2xl p-1 xl:p-5 text-center mt-2 xl:text-2xl 3xl:text-4xl"
          />
        </label>

        <label className="flex flex-col text-center sm:text-center sm:mb-2 sm:-mt-1 sm:text-sm xl:text-left xl:text-3xl 3xl:text-5xl mb-4">
          Mot de passe
          <input
            type="password"
            name="password"
            placeholder="Mot de passe"
            onChange={handleUser}
            value={user.password}
            className="text-xs border-solid border-2 border-loginBordure rounded-2xl p-1 xl:p-5 text-center mt-2 xl:text-2xl 3xl:text-4xl"
          />
        </label>

        <label className="block text-center text-xs sm:text-center sm:mb-2 sm:-mt-1 sm:text-base xl:text-left xl:text-3xl 3xl:text-4xl mb-6 xl:mt-2">
          Rester connecté
          <input
            type="checkbox"
            name="checkbox"
            onChange={handleCheckbox}
            checked={stayConnected}
            className="ml-4"
          />
          <span className="inline-block w-full float-none mt-10 text-blue-600 text-center sm:-mb-1 xl:text-2xl 3xl:text-4xl cursor-pointer xl:w-fit xl:float-right xl:mt-0 hover:text-yellow-600 hover:underline " onClick={handleForgotPasswordClick}>
            Mot de passe oublié
          </span>
        </label>

        <input type="submit" value="Se Connecter" className="submitButton bg-loginBordure hover:bg-blue-900 text-white font-semibold hover:text-white hover:font-bold text-xs sm:text-sm 3xl:text-4xl py-2 px-4 rounded-full xl:w-1/3 sm:w-32 mx-auto mt-4 mb-1 cursor-pointer" />
        <ForgotPassword isShowing={isForgotPasswordModalOpen} hide={closeForgotPasswordModal} />
        {error && <Error message={error} />}
      </form>
    </div>
  );
}
