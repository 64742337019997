import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTimes, FaPlus } from "react-icons/fa"; 
import { API_URL } from "../constants";
import { UserTypes } from "../constants";

const MachineAgencyChiefs = ({ agence }) => {
  const [users, setUsers] = useState({ chefsAgence: [], chefsAtelier: [] });
  const [chefAgence, setChefAgence] = useState(null);
  const [chefAtelier, setChefAtelier] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [initialChefAgence, setInitialChefAgence] = useState(null);
  const [initialChefAtelier, setInitialChefAtelier] = useState(null);
  const [showRemoveConfirmationModal, setShowRemoveConfirmationModal] = useState(false);
  const [showRemoveAtelierConfirmationModal, setShowRemoveAtelierConfirmationModal] = useState(false);
  const [usersWithoutAgence, setUsersWithoutAgence] = useState([]);
  const [selectedUserToAdd, setSelectedUserToAdd] = useState("");

  const userType = JSON.parse(localStorage.getItem("userType"));
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.auth_token;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getAgenceChiefs = async (agenceId) => {
    try {
      const chefAgenceResponse = await axios.get(`${API_URL}/agences/${agenceId}/chefag`, { headers });
      const chefAtelierResponse = await axios.get(`${API_URL}/agences/${agenceId}/chefat`, { headers });

      const chefAgence = chefAgenceResponse.data.data;
      const chefAtelier = chefAtelierResponse.data.data;

      return { chefAgence, chefAtelier };
    } catch (error) {
      console.error("Erreur lors de la récupération des chefs d'agence et d'atelier :", error.message);
      return { chefAgence: null, chefAtelier: null };
    }
  };

  const fetchUsersWithoutAgence = async () => {
    try {
      const response = await axios.get(`${API_URL}/users?type=2&type=3&agence=null`, { headers });

      const users = response.data.data;
      // Filtrer les utilisateurs qui ne sont pas encore liés à une agence
      const filteredUsers = users.filter((user) => user.agence == null && (user.type === 2 || user.type === 3));

      setUsersWithoutAgence(filteredUsers);
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs sans agence :", error.message);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${API_URL}/users`, {
          headers,
        });

        const usersData = response.data.data;

        const chefsAgence = usersData.filter((user) => user.type === 2 && user.agence);
        const chefsAtelier = usersData.filter((user) => user.type === 3 && user.agence);

        const chefsAgenceSansAgence = usersData.filter((user) => user.type === 2 && user.agence == null);
        const chefsAtelierSansAgence = usersData.filter((user) => user.type === 3 && !user.agence);

        const responseAgences = await axios.get(`${API_URL}/agences`, { headers });
        const agences = responseAgences.data.data;

        const chefsAgenceWithAgenceInfo = chefsAgence.map((chef) => {
          const agenceInfo = agences.find((agence) => agence.id === chef.agence.id);
          return { ...chef, agence: agenceInfo };
        });

        const chefsAtelierWithAgenceInfo = chefsAtelier.map((chef) => {
          const agenceInfo = agences.find((agence) => agence.id === chef.agence.id);
          return { ...chef, agence: agenceInfo };
        });

        setUsers({
          chefsAgence: [...chefsAgenceSansAgence],
          chefsAtelier: [...chefsAtelierSansAgence],
        });
      } catch (error) {
        console.error("Erreur lors de la récupération des chefs d'agence et d'atelier :", error.message);
      }
    };

    fetchUserData();
  }, [token]);

  useEffect(() => {
    if (agence) {
      const fetchAgenceChiefs = async () => {
        const { chefAgence, chefAtelier } = await getAgenceChiefs(agence.id);

        setChefAgence(chefAgence);
        setChefAtelier(chefAtelier);
        setInitialChefAgence(chefAgence); // Sauvegarde les chefs d'agence initiaux
        setInitialChefAtelier(chefAtelier); // Sauvegarde les chefs d'atelier initiaux
      };

      fetchAgenceChiefs();
    }
  }, [agence]);

  useEffect(() => {
    if (editMode) {
      fetchUsersWithoutAgence();
    }
  }, [editMode]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelRemove = () => {
    setShowRemoveConfirmationModal(false);
  };

  const handleConfirmRemove = async (userId, chefType) => {
    try {
      await axios.delete(`${API_URL}/agences/${agence.id}/removeAgent/${userId}`, { headers });
  
      if (chefType === 'agence') {
        setChefAgence(null);
      } else if (chefType === 'atelier') {
        setChefAtelier(null);
      }
  
      setShowRemoveConfirmationModal(false);
      window.location.reload();
    } catch (error) {
      console.error(`Erreur lors de la suppression du chef ${chefType} :`, error.message);
    }
  };
  

  const handleCancelRemoveAtelier = () => {
    setShowRemoveAtelierConfirmationModal(false);
  };

  const handleConfirmRemoveAtelier = async (userId) => {
    try {
      await axios.delete(`${API_URL}/agences/${agence.id}/removeAgent/${userId}`, { headers });
      // Mettez à jour le chef d'atelier en conséquence
      setChefAtelier(null);
      setShowRemoveAtelierConfirmationModal(false);
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de la suppression du chef d'atelier :", error.message);
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  const handleRemoveClick = () => {
    setShowRemoveConfirmationModal(true);
  };

  const handleRemoveAtelierClick = () => {
    setShowRemoveAtelierConfirmationModal(true);
  };

  const handleUserToAddChange = (event) => {
    setSelectedUserToAdd(event.target.value);
  };

  const handleAddUserToAgence = async () => {
    try {
      const agenceId = agence.id;
      const requestBody = {
        id: selectedUserToAdd,
      };

      // Utilisez une requête POST pour ajouter l'utilisateur à l'agence
      const response = await axios.post(
        `${API_URL}/agences/${agenceId}/users`,
        requestBody,
        { headers }
      );

      const { chefAgence: updatedChefAgence, chefAtelier: updatedChefAtelier } = await getAgenceChiefs(agence.id);
      setChefAgence(updatedChefAgence);
      setChefAtelier(updatedChefAtelier);

      setSelectedUserToAdd("");
      setEditMode(false);
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'utilisateur à l'agence :", error.message);
    }
  };

  const handleAddUserButtonClick = () => {
    setEditMode(true);
  };

  return (
    <div>
        <div>
  <div className="flex">
    <div className="font-bold underline mr-2 text-base mb-2">Chef d'agence :</div>
    <div className="text-sm md:text-base lg:text-xl 3xl:text-2xl">
      {chefAgence && chefAgence.length > 0 ? (
        chefAgence.map((chef) => (
          <div key={chef.id}>
            <div key={chef.id} className="flex items-center">
              {`${chef.nom} ${chef.prenom}`}
              {userType === UserTypes.SUPER && (
                <FaTimes
                  className="cursor-pointer ml-2 hover:text-red-500 mt-1 transition-all duration-200 ease-in-out"
                  onClick={() => handleConfirmRemove(chef.id, 'agence')}
                />
              )}
            </div>
          </div>
        ))
      ) : (
        <div>Aucun chef d'agence</div>
      )}
    </div>
  </div>
  <div className="flex">
    <div className="font-bold underline mr-2 text-base">Chefs d'atelier :</div>
    <div className="text-sm md:text-base lg:text-xl 3xl:text-2xl">
      {chefAtelier && chefAtelier.length > 0 ? (
        chefAtelier.map((chef) => (
          <div key={chef.id}>
            <div key={chef.id} className="flex items-center">
              {`${chef.nom} ${chef.prenom}`}
              {userType === UserTypes.SUPER && (
                <FaTimes
                  className="cursor-pointer ml-2 hover:text-red-500 mt-1 transition-all duration-200 ease-in-out"
                  onClick={() => handleConfirmRemove(chef.id, 'atelier')}
                />
              )}
            </div>
          </div>
        ))
      ) : (
        <div>Aucun chef d'atelier</div>
      )}
    </div>
  </div>
  {userType === UserTypes.SUPER || userType === UserTypes.ADMIN ? (
  <div className="flex items-center mt-2">
    <button
      className="text-xs sm:text-sm cursor-pointer transition-all duration-200 ease-in-out flex items-center px-4 py-2 border rounded-md bg-blue-500 hover:bg-blue-600 text-white"
      onClick={handleAddUserButtonClick}
    >
      <FaPlus className="mr-2" />Ajouter un utilisateur
    </button>
  </div>
) : null}
</div>
      {showRemoveConfirmationModal && (
        <div className="fixed inset-0 flex items-center justify-center z-10 bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-md shadow-lg">
            <p className="text-base sm:text-lg text-center font-semibold mb-2">
              Êtes-vous sûr de vouloir retirer le chef d'agence{" "}
              <div className="font-bold">
                {chefAgence.nom} {chefAgence.prenom}
              </div>{" "}
              de cette agence ?
            </p>
            <div className="flex justify-center">
              <button
                className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-md text-white font-semibold mr-5"
                onClick={handleCancelRemove}
              >
                Annuler
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-md text-white font-semibold"
                onClick={handleConfirmRemove(chefAgence.id)}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      )}
      {showRemoveAtelierConfirmationModal && (
        <div className="fixed inset-0 flex items-center justify-center z-10 bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-md shadow-lg">
            <p className="text-base sm:text-lg text-center font-semibold mb-2">
              Êtes-vous sûr de vouloir retirer le chef d'atelier{" "}
              <div className="font-bold">
                {chefAtelier.nom} {chefAtelier.prenom}
              </div>{" "}
              de cette agence ?
            </p>
            <div className="flex justify-center">
              <button
                className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-md text-white font-semibold mr-5"
                onClick={handleCancelRemoveAtelier}
              >
                Annuler
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-md text-white font-semibold"
                onClick={handleConfirmRemoveAtelier(chefAgence.id)}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      )}
      {editMode && (userType === UserTypes.SUPER || userType === UserTypes.ADMIN) && (
        <div className="mt-4">
          <label htmlFor="userToAddSelect" className="font-bold underline mr-2">
            Ajouter un utilisateur :
          </label>
          <select
            id="userToAddSelect"
            value={selectedUserToAdd}
            onChange={handleUserToAddChange}
            className="border-2 border-gray-300 rounded-md p-2 m-2 w-44 sm:w-56 text-base"
          >
            <option value="">Sélectionner un utilisateur</option>
            {usersWithoutAgence.map((user) => (
              <option key={user.id} value={user.id}>
                {user.nom} {user.prenom} ({user.type === 2 ? 'Chef d\'agence' : 'Chef d\'atelier'})
              </option>
            ))}
          </select>
          <button
            onClick={handleAddUserToAgence}
            className="bg-green-500 hover:bg-green-600 text-white rounded-md px-4 py-2 text-base ml-4"
          >
            Ajouter
          </button>
          <button
            onClick={handleCancelClick}
            className="bg-red-500 hover:bg-red-600 text-white rounded-md px-4 py-2 text-base ml-4 mt-2 lg:mt-0"
          >
            Annuler
          </button>
        </div>
      )}
    </div>
  );
};
export default MachineAgencyChiefs;