import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import axios from "axios";
import { FaTrash, FaEdit , FaCheckCircle } from "react-icons/fa";
import { UserTypes } from "../constants";

const MaintenanceExclu = ({ machineId, token, agenceId }) => {
  const [maintenanceData, setMaintenanceData] = useState(null);
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [maintenanceToDelete, setMaintenanceToDelete] = useState(null);
  const [newMaintenanceName, setNewMaintenanceName] = useState("");
  const [newMaintenanceDescription, setNewMaintenanceDescription] = useState("");
  const userType = JSON.parse(localStorage.getItem("userType"));
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [chefAtelierId, setChefAtelierId] = useState(null);
const [editingName, setEditingName] = useState(null); // Pour suivre l'édition du nom
const [editingDescription, setEditingDescription] = useState(null); // Pour suivre l'édition de la description
const [nameErrorMessageVisible, setNameErrorMessageVisible] = useState(false);
const [descriptionErrorMessageVisible, setDescriptionErrorMessageVisible] = useState(false);
const [nameErrorMessage, setNameErrorMessage] = useState("");
const [descriptionErrorMessage, setDescriptionErrorMessage] = useState("");
const [maintenanceInterventionFrequencies, setMaintenanceInterventionFrequencies] = useState({});


  useEffect(() => {
    const fetchMaintenanceData = async () => {
      try {
        const response = await axios.get(`${API_URL}/machines/${machineId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        setMaintenanceData(response.data.maintenanceMachines);
        setType(response.data.typeMachine)
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de maintenance :", error.message);
        setLoading(false);
      }
    };

    fetchMaintenanceData();
  }, [machineId, token]);

  // ... Code pour obtenir le nom du type de machine associé ...

  useEffect(() => {
    const fetchChefAtelierId = async () => {
      try {
        const response = await axios.get(`${API_URL}/agences/${agenceId}/chefat`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Response from API:", response.data);
        setChefAtelierId(response.data.id);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'ID du chef d'atelier :", error.message);
        setChefAtelierId(null);
      }
    };

    if (userType === UserTypes.CHEFAT) {
      fetchChefAtelierId();
    }
  }, [agenceId, token, userType]);

  const handleDeleteClick = (maintenance) => {
    setMaintenanceToDelete(maintenance);
    setModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!maintenanceToDelete) return;

    try {
      // Supprimer la maintenance
      await axios.delete(`${API_URL}/maintenancesmachine/${maintenanceToDelete.id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Mettre à jour localement la liste des maintenances
      setMaintenanceData((prevData) =>
        prevData.filter((maintenance) => maintenance.id !== maintenanceToDelete.id)
      );
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de la suppression de la maintenance :", error.message);
    } finally {
      setMaintenanceToDelete(null);
      setModalOpen(false);
    }
  };

  const handleFrequencyChange = (e, maintenanceId) => {
    const { value } = e.target;
    setMaintenanceInterventionFrequencies((prevFrequencies) => ({
      ...prevFrequencies,
      [maintenanceId]: value,
    }));
  };
  
  const handleFrequencyValidation = async (maintenanceId) => {
    try {
      // Récupérer la fréquence d'intervention à partir du state
      const interventionFrequency = maintenanceInterventionFrequencies[maintenanceId];
      const numericFrequency = parseInt(interventionFrequency);
  
      // Effectuer la requête PATCH à l'API avec l'ID de la maintenance et la nouvelle valeur de la fréquence d'intervention
      await axios.patch(
        `${API_URL}/maintenancesmachine/${maintenanceId}`,
        { freq_intervention: numericFrequency },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la fréquence d'intervention :", error.message);
    }
  };

  const getCurrentDate = () => {
    const currentDate = new Date().toISOString();
    return currentDate;
  };

  const handleUpdateNextMaintenance = async (maintenanceId) => {
    try {
      // Effectuer une requête GET à l'API pour obtenir les détails de la maintenance
      const response = await axios.get(`${API_URL}/maintenancesmachine/machines/${machineId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Trouver la maintenance spécifique à partir de la réponse en utilisant son ID
      const maintenanceToUpdate = response.data.find(maintenance => maintenance.id === maintenanceId);
  
      if (!maintenanceToUpdate) {
        console.error("Maintenance non trouvée.");
        return;
      }
  
      // Extraire la valeur de la fréquence d'intervention de la description de la maintenance
      const interventionFrequency = maintenanceToUpdate.freq_intervention;
  
      // Effectuer la requête PATCH à l'API avec l'ID de la maintenance et la nouvelle valeur de la fréquence d'intervention
      await axios.patch(
        `${API_URL}/maintenancesmachine/${maintenanceId}`,
        { heures: interventionFrequency,
          etat: "A_JOUR",
          datemaintenance: getCurrentDate()
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
       window.location.reload();
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'échéance de la prochaine intervention de maintenance :", error.message);
    }
  };
  

  

// Fonction pour activer l'édition du nom de la maintenance
const handleEditMaintenanceName = (maintenance) => {
  if (userType === UserTypes.CHEFAT && chefAtelierId !== userId) {
    console.log("Vous n'êtes pas autorisé à modifier cette maintenance.");
    return;
  }
  setEditingName(maintenance);
  setNewMaintenanceName(maintenance.nom);
  setNameErrorMessageVisible(false);
};

// Fonction pour activer l'édition de la description de la maintenance
const handleEditMaintenanceDescription = (maintenance) => {
  if (userType === UserTypes.CHEFAT && chefAtelierId !== userId) {
    console.log("Vous n'êtes pas autorisé à modifier cette maintenance.");
    return;
  }
  setEditingDescription(maintenance);
  setNewMaintenanceDescription(maintenance.description);
  setDescriptionErrorMessageVisible(false);
};


  // Fonction pour annuler l'édition de la maintenance
  const handleCancelEdit = () => {
    setNewMaintenanceName("");
    setNewMaintenanceDescription("");
    setEditingDescription(null); // Désactiver l'édition de la description
    setEditingName(null)
  };

 // Fonction pour valider la modification du nom de la maintenance
const handleSaveMaintenanceName = async () => {
  try {
    await axios.patch(
      `${API_URL}/maintenancesmachine/${editingName.id}`,
      { nom: newMaintenanceName },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Mettre à jour localement le nom de la maintenance
    setMaintenanceData((prevData) =>
      prevData.map((maintenance) =>
        maintenance.id === editingName.id
          ? { ...maintenance, nom: newMaintenanceName }
          : maintenance
      )
    );

    setEditingName(null);
    setNewMaintenanceName("");
    setNameErrorMessage("");
    setNameErrorMessageVisible(false);
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      setNameErrorMessage(error.response.data.message);
      setNameErrorMessageVisible(true);

      setTimeout(() => {
        setNameErrorMessageVisible(false);
      }, 3000);
    } else {
      setNameErrorMessage("Une erreur s'est produite lors de la modification du nom de la maintenance.");
    }
  }
};

// Fonction pour valider la modification de la description de la maintenance
const handleSaveMaintenanceDescription = async () => {
  try {
    await axios.patch(
      `${API_URL}/maintenancesmachine/${editingDescription.id}`,
      { description: newMaintenanceDescription },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Mettre à jour localement la description de la maintenance
    setMaintenanceData((prevData) =>
      prevData.map((maintenance) =>
        maintenance.id === editingDescription.id
          ? { ...maintenance, description: newMaintenanceDescription }
          : maintenance
      )
    );

    setEditingDescription(null);
    setNewMaintenanceDescription("");
    setDescriptionErrorMessage("");
    setDescriptionErrorMessageVisible(false);
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      setDescriptionErrorMessage(error.response.data.message);
      setDescriptionErrorMessageVisible(true);

      setTimeout(() => {
        setDescriptionErrorMessageVisible(false);
      }, 3000);
    } else {
      setDescriptionErrorMessage("Une erreur s'est produite lors de la modification de la description de la maintenance.");
    }
  }
};


  const getMaintenanceStatusText = (etat) => {
    switch (etat) {
      case 'A_JOUR':
        return <span className="text-green-600 font-bold">À jour</span>;
      case 'A_PREVOIR':
        return <span className="text-orange-600 font-bold">À prévoir</span>;
      case 'A_FAIRE':
        return <span className="text-red-600 font-bold">À faire</span>;
      default:
        return etat;
    }
  };

  return (
    <div>
      <div className="border-solid border-4 border-r-0 border-l-0 border-loginBordure pt-4 pb-4 text-sm lg:text-xl 3xl:text-3xl">
        {loading ? (
          <p>Chargement en cours...</p>
        ) : maintenanceData && maintenanceData.length > 0 ? (
          <>
            {maintenanceData.map((maintenance) => (
      <div
        key={maintenance.id}
        className="mb-4 border-solid border-4 border-r-0 border-l-0 border-t-0 border-loginBordure pt-4 pb-4"
      >
        <div className="flex items-center mb-4">
          <h5 className="text-lg sm:text-2xl font-bold">
          {editingName && editingName.id === maintenance.id ? (
    <>
      <input
        type="text"
        className="border rounded-md px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
        value={newMaintenanceName}
        onChange={(e) => setNewMaintenanceName(e.target.value)}
      />
      <button
        className="bg-green-500 hover:bg-green-600 px-2 py-1 rounded-md text-white font-semibold text-sm ml-2"
        onClick={handleSaveMaintenanceName}
      >
        Valider
      </button>
      <button
        className="bg-red-500 hover:bg-red-600 px-2 py-1 rounded-md text-white font-semibold text-sm ml-2"
        onClick={handleCancelEdit}
      >
        Annuler
      </button>
      {nameErrorMessageVisible && nameErrorMessage && (
        <p className="text-red-500">{nameErrorMessage}</p>
      )}
    </>
  ) : (
    <>
      <span>{maintenance.nom}</span>
      {userType === UserTypes.SUPER || userType === UserTypes.ADMIN || (userType === UserTypes.CHEFAT && chefAtelierId === userId) ? (
        <button
          className="hover:text-blue-600 transition-all duration-200 ease-in-out ml-2"
          onClick={() => handleEditMaintenanceName(maintenance)}
        >
          <FaEdit />
        </button>
      ) : null}
      <span className="ml-2 font-light">{getMaintenanceStatusText(maintenance.etat)}</span>
    </>
  )}
          </h5>
        </div>
        <span className="flex text-gray-700 text-xs lg:text-xl font-bold 3xl:text-xl">Description :
        {editingDescription && editingDescription.id === maintenance.id ? (
    <>
      <input
        type="text"
        className="border rounded-md px-2 py-1 focus:outline-none focus:ring focus:border-blue-300 ml-2"
        value={newMaintenanceDescription}
        onChange={(e) => setNewMaintenanceDescription(e.target.value)}
      />
      <button
        className="bg-green-500 hover:bg-green-600 px-2 py-1 rounded-md text-white font-semibold text-sm ml-2"
        onClick={handleSaveMaintenanceDescription}
      >
        Valider
      </button>
      <button
        className="bg-red-500 hover:bg-red-600 px-2 py-1 rounded-md text-white font-semibold text-sm ml-2"
        onClick={handleCancelEdit}
      >
        Annuler
      </button>
      {descriptionErrorMessageVisible && descriptionErrorMessage && (
        <p className="text-red-500">{descriptionErrorMessage}</p>
      )}
    </>
  ) : (
    <>
      <span className="ml-2 font-light">{maintenance.description}</span>
      {userType === UserTypes.SUPER || userType === UserTypes.ADMIN || (userType === UserTypes.CHEFAT && chefAtelierId === userId) ? (
        <button
          className="hover:text-blue-600 transition-all duration-200 ease-in-out ml-2"
          onClick={() => handleEditMaintenanceDescription(maintenance)}
        >
          <FaEdit />
        </button>
      ) : null}
    </>
  )}
        </span>
        <span className="flex text-gray-700 text-xs lg:text-xl font-bold 3xl:text-xl">Date de création :
  <p className="ml-2 font-light">{new Date(maintenance.date).toLocaleDateString()}</p>
      </span>
        <span className="flex text-gray-700 text-xs lg:text-xl font-bold 3xl:text-xl">Echéance avant la prochaine intervention :
        <p className="ml-2 font-light">{maintenance.heures}h</p>
        </span>
        <span className="flex text-gray-700 text-xs lg:text-xl font-bold 3xl:text-xl">
              Exclusive à la machine :
                <p className="ml-2 font-light text-red-600">Oui</p>
            </span>
        <span className="flex text-gray-700 text-xs lg:text-xl font-bold 3xl:text-xl">Type de machine associé :
  <p className="ml-2 font-light">
    {type !== null && type !== undefined ? (type.nom !== null && type.nom !== undefined ? type.nom : "Pas de type de machine associé") : "Pas de type de machine associé"}
  </p>
</span>

        {/* Vous pouvez ajouter d'autres informations ici */}
        {userType === UserTypes.SUPER || userType === UserTypes.ADMIN ? (
  <div className="">
    <div className="flex items-center mb-3 lg:mb-0 mt-2">
      <span className="text-gray-700 text-xs lg:text-xl font-bold 3xl:text-xl">
        Fréquence d'intervention :
      </span>{' '}
     <input
  className="text-xs lg:text-base 3xl:text-xl text-center border-2 border-orangeButton hover:border-bleuSTB rounded-xl p-2 w-[65%] sm:w-[50%] ml-2"
  type="number"
  value={maintenanceInterventionFrequencies[maintenance.id] || maintenance.freq_intervention || ''}
  onChange={(e) => handleFrequencyChange(e, maintenance.id)}
/>



      <span className="text-base lg:text-xl ml-2 mr-2">h</span>
      <button
  className="bg-bleuSTB 3xl:text-xl text-white text-base rounded-xl w-20 sm:w-[47%] cursor-pointer hover:bg-marronSTB ml-2"
  onClick={() => handleFrequencyValidation(maintenance.id)}
>
  Valider
</button>

    </div>
    {maintenance.datemaintenance && maintenance.datemaintenance !== "null" && !isNaN(new Date(maintenance.datemaintenance)) && (
  <span className="flex text-gray-700 text-xs lg:text-xl font-bold 3xl:text-xl">Dernière intervention :
    <p className="ml-2 font-light">{new Date(maintenance.datemaintenance).toLocaleDateString()}</p>
  </span>
)}
    <div className="flex flex-col lg:flex-row items-start mt-4">
    <button
  className="flex flex-row items-center bg-green-600 hover:bg-green-700 text-white text-xs 3xl:text-xl border-none rounded-xl px-2 sm:px-3 py-1 no-underline cursor-pointer mb-3 lg:mb-0 lg:mr-2 w-21 sm:w-auto"
  onClick={() => handleUpdateNextMaintenance(maintenance.id)}
>
  <FaCheckCircle className="text-xl sm:text-2xl mr-2" />
  <span>Mettre à jour</span>
</button>

      <button
        className="text-orangeButton flex items-center hover:text-orange-600"
        onClick={() => handleDeleteClick(maintenance)}
      >
        <FaTrash className="mr-1" />
        Supprimer
      </button>
    </div>
  </div>
) : null}
      </div>
    ))}
  </>
) : (
  <p>Aucune maintenance exclusive associée à cette machine.</p>
)}
      </div>
      {modalOpen && maintenanceToDelete && (
        <div className="fixed inset-0 flex items-center justify-center z-10 bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-md shadow-lg">
            <p className="text-base sm:text-lg text-center font-semibold mb-2">
              Confirmer la suppression de la maintenance :
            </p>
            <p className="text-xl text-center font-bold mb-4">{maintenanceToDelete.nom}</p>
            <div className="flex justify-center">
              <button
                className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-md text-white font-semibold mr-5"
                onClick={() => setModalOpen(false)}
              >
                Annuler
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-md text-white font-semibold"
                onClick={handleConfirmDelete}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      )}
      




    </div>
  );
};

export default MaintenanceExclu;
