import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

const Modal = ({ isShowing, hide, title, ...props }) =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
            <div className="relative bg-white rounded-lg max-w-3xl w-11/12 sm:w-full p-4">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="text-black text-2xl font-bold leading-none py-1 px-2 ml-auto lg:text-3xl 3xl:text-4xl"
                  onClick={hide}
                >
                  &times;
                </button>
              </div>
              <div className="text-2xl text-center mb-4">
                <h4 className="3xl:text-5xl">{title}</h4>
              </div>
              <div>{props.children}</div>
            </div>
          </div>
        </>,
        document.body
      )
    : null;

Modal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default Modal;
