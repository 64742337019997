import { Routes, Route, Navigate } from 'react-router-dom';
import React, { useState } from 'react';

import Login from '../src/Login/Login';
import MachinesList from './MachinesList/MachinesList';
import Admin from './Admin/Admin';
import MachinesDetails from './MachinesDetails/MachinesDetails';

const AppRoutes = () => {
  const [user, setUser] = useState(null);

  return (
    <Routes>
      <Route
        path="/"
        element={user ? <Navigate to="/machineslist" /> : <Navigate to="/login" />}
      />
      <Route path="/login" element={<Login setUser={setUser} />} />
      <Route
        path="/machineslist"
        element={<MachinesList user={user} />}
      />
      <Route
        path="/details"
        element={<MachinesDetails user={user} />}
      />
      <Route
        path="/admin"
        element={<Admin user={user} />}
      />
    </Routes>
  );
};

export default AppRoutes;
