import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { API_URL } from "../constants";
import Chart from "chart.js/auto";

const Capteur = ({ machineId, token }) => {
  const [capteurData, setCapteurData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editingName, setEditingName] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newName, setNewName] = useState("");
  const [heuresUtilisation, setHeuresUtilisation] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(null);
  const [batteryData, setBatteryData] = useState({
    labels: [],
    datasets: [
      {
        label: "Niveau de batterie",
        data: [],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  });

  const handleDeleteChartData = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDeleteChartData = () => {
    const initialBatteryData = {
      labels: [],
      datasets: [
        {
          label: "Niveau de batterie",
          data: [],
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    };

    setBatteryData(initialBatteryData);
    saveBatteryDataToLocalStorage(initialBatteryData);
    window.location.reload();

    setShowDeleteModal(false);
  };

  const handleDeleteLastChartData = () => {
    const currentData = { ...batteryData };

    currentData.labels.pop();
    currentData.datasets[0].data.pop();

    setBatteryData(currentData);

    saveBatteryDataToLocalStorage(currentData);

    if (chartRef.current) {
      chartRef.current.update();
    }
  };

  const saveBatteryDataToLocalStorage = (data) => {
    // Utilisez l'ID du capteur comme partie de la clé localStorage
    const localStorageKey = `batteryData_${capteurData ? capteurData.id : 'unknown'}`;
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  };

  const loadBatteryDataFromLocalStorage = () => {
    // Utilisez l'ID du capteur comme partie de la clé localStorage
    const localStorageKey = `batteryData_${capteurData ? capteurData.id : 'unknown'}`;
    const data = localStorage.getItem(localStorageKey);
    return data ? JSON.parse(data) : batteryData;
  };

  const [lastRequestTime, setLastRequestTime] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchCapteurData = async () => {
      try {
        const response = await axios.get(`${API_URL}/machines/${machineId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200 && response.data.capteur) {
          setHeuresUtilisation(response.data.heuresUtilisation);
          setCapteurData(response.data.capteur);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de capteur :", error.message);
      }
    };

    fetchCapteurData();
  }, [machineId]);

  const fetchBatteryLevel = async () => {
    try {
      const response = await axios.get(`${API_URL}/capteurs/${capteurData.id}/batterie`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data.batterie) {
        const currentTime = new Date();
        const batteryLevel = response.data.batterie;

        const day = currentTime.getDate().toString().padStart(2, '0');
        const month = (currentTime.getMonth() + 1).toString().padStart(2, '0');
        const year = currentTime.getFullYear();
        const hours = currentTime.getHours().toString().padStart(2, '0');
        const minutes = currentTime.getMinutes().toString().padStart(2, '0');
        const formattedTime = `${day}/${month}/${year} ${hours}:${minutes}`;

        const updatedData = {
          labels: [...batteryData.labels, formattedTime],
          datasets: [
            {
              ...batteryData.datasets[0],
              data: [...batteryData.datasets[0].data, batteryLevel],
            },
          ],
        };

        setBatteryData(updatedData);
        saveBatteryDataToLocalStorage(updatedData);
        setLastRequestTime(currentTime);

        if (chartRef.current) {
          chartRef.current.update();
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des informations de batterie du capteur :", error.message);
    }
  };

  setInterval(fetchBatteryLevel, 1800 * 1000);

  useEffect(() => {
    const savedData = loadBatteryDataFromLocalStorage();
    setBatteryData(savedData);
    saveBatteryDataToLocalStorage(savedData);

    if (canvasRef.current) {
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      const ctx = canvasRef.current;

      const newChart = new Chart(ctx, {
        type: "line",
        data: savedData,
      });

      chartRef.current = newChart;
    }
  }, [capteurData]);

  const handleRefreshBatteryData = () => {
    setRefreshing(true);
    fetchBatteryLevel();
    setRefreshing(false);
  };

  const handleUploadCapteurData = async () => {
    const dataToSend = {
      id: capteurData.id,
      batterie: capteurData.batterie,
      tempsUtilisation: heuresUtilisation,
      frequency: "now",
    };

    try {
      await axios.post(`${API_URL}/capteurs/upload/single`, dataToSend, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setEmailSent(true);
      setTimeout(() => {
        setEmailSent(false);
      }, 5000);
    } catch (error) {
      console.error("Erreur lors de l'envoi des données du capteur :", error.message);
      setError("Une erreur s'est produite lors de l'envoi des données du capteur.");
    }
  };

  const handleActivateCapteur = async () => {
    try {
      await axios.patch(`${API_URL}/capteurs/${capteurData.id}`, { actif: true }, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setCapteurData({ ...capteurData, actif: true });
    } catch (error) {
      console.error("Erreur lors de la mise en statut actif du capteur :", error.message);
    }
  };

  const handleDeactivateCapteur = async () => {
    try {
      await axios.patch(`${API_URL}/capteurs/${capteurData.id}`, { actif: false }, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setCapteurData({ ...capteurData, actif: false });
    } catch (error) {
      console.error("Erreur lors de la mise en statut inactif du capteur :", error.message);
    }
  };

  const handleDeleteAndDissociateCapteur = async () => {
    try {
      // Envoyer la requête DELETE pour dissocier le capteur de la machine
      await axios.delete(`${API_URL}/machines/${machineId}/capteurs`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Ensuite, supprimer le capteur
      await axios.delete(`${API_URL}/capteurs/${capteurData.id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Mettre à jour l'état local
      setCapteurData(null);
      setShowModal(false);
    } catch (error) {
      console.error("Erreur lors de la suppression et de la dissociation du capteur :", error.message);
    }
  };

  const handleEditName = () => {
    setEditingName(true);
    setNewName(capteurData.nom); // Assurez-vous de récupérer le nom du capteur actuel ici
  };

  const handleCancelEdit = () => {
    setEditingName(false);
    setNewName("");
  };

  const handleSaveName = async () => {
    try {
      await axios.patch(`${API_URL}/capteurs/${capteurData.id}`, { nom: newName }, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setCapteurData({ ...capteurData, nom: newName });
      setEditingName(false);
      setNewName("");
    } catch (error) {
      console.error("Erreur lors de la modification du nom du capteur :", error.message);
    }
  };

  return (
    <div className="p-4 rounded-lg shadow-xl bg-white">
      <h4 className="text-3xl lg:text-5xl 3xl:text-6xl text-center 3xl:text-6xl font-semibold mb-6">Capteur associé</h4>
      {capteurData ? (
        <div className="space-y-2">
          <div className="flex items-center">
            <div className="font-bold underline mr-2 text-sm sm:text-3xl">Nom du capteur :</div>
            {!editingName ? (
              <div className="font-light">{capteurData.nom}</div>
            ) : (
              <div className="inline items-center">
                <input
                  type="text"
                  className="border rounded-md px-2 py-1 focus:outline-none focus:ring focus:border-blue-300 mb-4 sm:mb-0 w-40 sm:w-56"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                />
                <button
                  className="bg-green-500 hover:bg-green-600 text-white font-semibold py-1 px-3 rounded-md shadow-md transition-all duration-200 ease-in-out ml-2 text-sm"
                  onClick={handleSaveName}
                >
                  Valider
                </button>
                <button
                  className="bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-3 rounded-md shadow-md transition-all duration-200 ease-in-out ml-2 text-sm"
                  onClick={handleCancelEdit}
                >
                  Annuler
                </button>
              </div>
            )}
            {!editingName && (
              <button
                className="hover:text-blue-500 transition-all duration-200 ease-in-out ml-4"
                onClick={handleEditName}
              >
                <FaEdit />
              </button>
            )}
          </div>
          <div className="flex items-center">
            <div className="font-bold underline mr-2">Batterie :</div>
            {capteurData.batterie ? (
              <div className="font-light">{capteurData.batterie}%</div>
            ) : (
              <div className="font-light">Non renseigné</div>
            )}
          </div>
          <div className="flex items-center">
            <div className="font-bold underline mr-2">Statut :</div>
            <div className={`font-light ${capteurData.actif ? "text-green-600" : "text-red-600"}`}>
              {capteurData.actif ? "Actif" : "Inactif"}
            </div>
            {!capteurData.actif ? (
              <button
                className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md shadow-md transition-all duration-200 ease-in-out hover:underline ml-10 text-xs sm:text-sm"
                onClick={handleActivateCapteur}
              >
                Activer
              </button>
            ) : (
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md shadow-md transition-all duration-200 ease-in-out hover:underline ml-10 text-xs sm:text-sm"
                onClick={handleDeactivateCapteur}
              >
                Désactiver
              </button>
            )}
          </div>
          <div className="justify-end mt-4 space-x-4 flex-col ml-10 mr-10 xl:flex-row">
            <button
              className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md shadow-md transition-all duration-200 ease-in-out text-xl ml-3 sm:text-sm mb-2 xl:mb-0"
              onClick={() => setShowModal(true)}
            >
              Supprimer
            </button>
            <button
              className={`bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md shadow-md transition-all duration-200 ease-in-out text-xs sm:text-sm mb-2 xl:mb-0`}
              onClick={handleUploadCapteurData}
            >
              Envoyer l'e-mail de maintenance
            </button>
            <button
              className={`bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md shadow-md transition-all duration-200 ease-in-out text-xs sm:text-sm mb-2 xl:mb-0 ${refreshing ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handleRefreshBatteryData}
              disabled={refreshing}
            >
              {refreshing ? "Actualisation en cours..." : "Actualiser"}
            </button>
          </div>
        </div>
      ) : (
        <p className="font-light">Aucun capteur associé à cette machine</p>
      )}
      <div className="mt-6">
        <div>
          <canvas ref={canvasRef} id="battery-chart" />
        </div>
        <div className="mt-4">
          <button
            className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-md text-white font-semibold"
            onClick={handleDeleteChartData}
          >
            Supprimer les données du graphique
          </button>
        </div>
           {/* <div className="mt-4">
        <button
          className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-md text-white font-semibold"
          onClick={handleDeleteLastChartData}
        >
          Supprimer la dernière donnée du graphique
        </button>
      </div> */}
        {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-10 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 space-y-4 shadow-lg">
            <p className="text-center">Êtes-vous sûr de vouloir supprimer les données du graphique ?</p>
            <div className="flex justify-end space-x-4">
              <button
                className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-md text-white font-semibold"
                onClick={() => setShowDeleteModal(false)}
              >
                Annuler
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-md text-white font-semibold"
                onClick={handleConfirmDeleteChartData}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-10 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 space-y-4 shadow-lg">
            <p className="text-center">Êtes-vous sûr de vouloir supprimer ce capteur ?</p>
            <div className="flex justify-end space-x-4">
              <button
                className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-md text-white font-semibold"
                onClick={() => setShowModal(false)}
              >
                Annuler
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-md text-white font-semibold"
                onClick={handleDeleteAndDissociateCapteur}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      )}
      {
        emailSent && (
          <div className="bg-green-100">
            <strong>

            </strong>
          </div>
        )
      }
          {emailSent && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4 text-center text-2xl" role="alert">
              <strong className="font-bold">Succès !</strong>
              <span className="block sm:inline"> L'e-mail de maintenance a été envoyé avec succès.</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg
                  className="fill-current h-6 w-6 text-green-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  onClick={() => setEmailSent(false)}
                >
                  <title>Fermer</title>
                  <path
                    d="M14.293 5.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </span>
            </div>
          )}

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4 text-center text-2xl" role="alert">
              <strong className="font-bold">Erreur !</strong>
              <span className="block sm:inline"> {error}</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg
                  className="fill-current h-6 w-6 text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  onClick={() => setError(null)}
                >
                  <title>Fermer</title>
                  <path
                    d="M14.293 5.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </span>
            </div>
          )}
    </div>
  );
};

export default Capteur;