import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export default function ChangeTypeForm({ id, rafraichir, token }) {
  const [newuser, setNewuser] = useState({ type: "" });
  const [messageType, setMessageType] = useState("");
  const navigate = useNavigate();

  const showMessagePopup = (message) => {
    alert(message);
  };

  const clearMessages = () => {
    setTimeout(() => {
      setMessageType("");
    }, 5000);
  };

  useEffect(() => {
    clearMessages();
  }, [messageType]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const valueType = value;
  
    setNewuser({
      ...newuser,
      [name]: valueType,
    });
  };
  

  const handleSubmit = (event) => {
    event.preventDefault();

    const userId = id;
    const userType = (newuser.type);

    axios
      .patch(
        `${API_URL}/users/${userId}`,
        {
          type: userType
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        if (response.status === 401) {
          showMessagePopup(
            "ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer."
          );
          localStorage.clear();
          navigate.push("/login");
        } else {
          return response.data.data;
        }
      })
      .then(
        (result) => {
          setMessageType("Niveau d'accès modifié");
          rafraichir();
          window.location.reload();
        },
        (error) => {
          console.log(error.message);
        }
      );
  };

  useEffect(() => {
    axios
      .get(`${API_URL}/users`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        const selectedUser = response.data.data.find((user) => user.id === id);
        if (selectedUser) {
          setNewuser({
            type: selectedUser.type || ""
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          showMessagePopup(
            "ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer."
          );
          localStorage.clear();
          navigate("/login");
        } else {
          console.log(error.message);
        }
      });
  }, [id, token, navigate]);

  const getMessageClass = () => {
    if (messageType === "Niveau d'accès modifié") {
      return "bg-green-500";
    }
    return "bg-red-500";
  };

  // const mapTypeToValue = (type) => {
  //   const typeMap = {
  //     "": "0",
  //     "1": "1",
  //     "2": "2",
  //     "3": "3",
  //     "4": "4",
  //   };
  
  //   return typeMap[type] || "";
  // };

  const mapValueToType = (value) => {
    const typeMap = {
      super: "",
      admin: "1",
      chefag: "2",
      chefat: "3",
      capteur: "4",
    };
  
    return typeMap[value] || "";
  };
  
  
  return (
    <form onSubmit={handleSubmit} className="form">
      <label className="flex flex-col">
        <select
          className="text-sm md:text-base lg:text-xl 3xl:text-2xl border-4 border-loginBordure rounded-3xl p-4 text-center mt-4 mb-4"
          onChange={handleChange}
          value={(newuser.type)}
          name="type"
        >
          <option value="">Nouveau niveau d'accès</option>
          <option value="0">SuperAdmin</option>
          <option value="1">Admin</option>
          <option value="2">Chef d'agence</option>
          <option value="3">Chef d'atelier</option>
          <option value="4">Capteur</option>
        </select>
      </label>
      <div className="text-center">
        <input
          type="submit"
          value="Valider"
          className="bg-loginBordure hover:bg-blue-900 text-white font-semibold hover:text-white hover:font-bold text-sm 3xl:text-xl border-none rounded-full p-2 w-11/12 lg:w-60 mr-auto ml-auto cursor-pointer"
        />
        {messageType && (
          <span
            id="message"
            className={`${getMessageClass()} rounded-lg text-white mx-auto p-2 text-center my-4 text-xl flex items-center justify-center`}
          >
            {messageType}
          </span>
        )}
      </div>
    </form>
  );
}
