export const API_URL = "https://back-end.rugotech.dev.alf-environnement.net"
// export const API_URL = "http://localhost:5000"

export const UserTypes = {
  SUPER: 0,
  ADMIN: 1,
  CHEFAG: 2,
  CHEFAT: 3,
  CAPTEUR: 4,
};
