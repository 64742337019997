// reducers.js
const initialState = {};

const batteryLevelCacheReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_BATTERY_LEVEL_CACHE':
      return {
        ...state,
        [action.payload.machineId]: action.payload.batteryLevel,
      };
    default:
      return state;
  }
};

export default batteryLevelCacheReducer;
