import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { UserTypes } from "../constants";

const MachineModel = ({ typeId, token}) => {
  const [model, setModel] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [newModel, setNewModel] = useState("");
  const userType = JSON.parse(localStorage.getItem("userType"));

  useEffect(() => {
    const fetchModelData = async () => {
      try {
        const response = await axios.get(`${API_URL}/typesmachine/${typeId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        // Assurez-vous que le serveur renvoie correctement les informations du modèle dans la réponse
        // Par exemple, si la réponse est un objet avec un champ 'model', vous pouvez l'utiliser comme suit :
        setModel(response.data.modele);
        setNewModel(response.data.modele); // Pré-remplir le nouveau modèle avec le modèle actuel
      } catch (error) {
        console.error("Erreur lors de la récupération des informations du modèle :", error.message);
      }
    };

    fetchModelData();
  }, [typeId, token]);

  const handleEditClick = () => {
    if (userType === UserTypes.SUPER || userType === UserTypes.ADMIN) {
      setEditMode(true);
    } else {
      alert("Vous n'êtes pas autorisé à modifier le modèle.");
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setNewModel(model); // Réinitialiser le nouveau modèle avec le modèle actuel en cas d'annulation
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.patch(
        `${API_URL}/typesmachine/${typeId}`,
        {
          modele: newModel, // Utiliser le nouveau modèle dans la requête de mise à jour
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data) {
        setEditMode(false);
        // Mettre à jour le modèle affiché après la mise à jour réussie
        setModel(newModel);
      } else {
        console.error("Erreur lors de la mise à jour du modèle de la machine");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du modèle de la machine", error);
    }
  };

  return (
    <div className="flex">
      {editMode ? (
        <div className="inline items-center mb-4">
          <input
            type="text"
            value={newModel}
            onChange={(e) => setNewModel(e.target.value)}
            className="border-2 border-gray-300 rounded-md p-2 mr-2 w-44 sm:w-56 mb-4"
          />
          <button
            onClick={handleSaveClick}
            className="bg-loginBordure hover:bg-blue-900 text-white rounded-md px-4 py-2 text-base"
          >
            Valider
          </button>
          <button
            onClick={handleCancelClick}
            className="bg-red-500 hover:bg-red-700 text-white rounded-md px-4 py-2 ml-2 text-base"
          >
            Annuler
          </button>
        </div>
      ) : (
        <>
          <div className="font-bold underline mr-2 text-base mb-2">Modèle : </div>
          <div className="text-sm md:text-base lg:text-xl 3xl:text-2xl">{model}</div>
          {userType === UserTypes.SUPER || userType === UserTypes.ADMIN ? (
            <FaEdit className="cursor-pointer ml-4 hover:text-blue-500 transition-all duration-200 ease-in-out" onClick={handleEditClick} />
          ) : null}
        </>
      )}
    </div>
  );
};

export default MachineModel;