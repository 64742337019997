import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export default function MaintenanceCreation({ machineId, token }) {
  const [isCreatingMaintenance, setIsCreatingMaintenance] = useState(false);
  const navigate = useNavigate();
  const [allMaintenances, setAllMaintenances] = useState([]);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [matchingMaintenances, setMatchingMaintenances] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [maintenanceData, setMaintenanceData] = useState({
    heures: 0,
    date: new Date().toISOString(),
    nom: "",
    description: "",
    etat: "A_JOUR",
    freq_intervention: 0,
    datemaintenance: "",
    maintenance: {
      id: "a76cab8f-d158-4af3-87ce-2aec72f2ad97",
    },
    machine: {
      id: machineId,
    },
  });

  useEffect(() => {
    // Obtenez toutes les maintenances au chargement du composant
    axios
      .get(`${API_URL}/maintenancesmachine`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const allMaintenances = response.data.data;
        setAllMaintenances(allMaintenances);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token]);

  useEffect(() => {
    // Filtrez la liste complète des maintenances en fonction du terme de recherche
    if (searchTerm.trim() !== "") {
      const filteredMaintenances = allMaintenances.filter((maintenance) =>
        maintenance.nom.toLowerCase().includes(searchTerm.toLowerCase())
      );

      // Utilisez un ensemble (Set) pour stocker les noms uniques
      const uniqueNames = new Set(filteredMaintenances.map((maintenance) => maintenance.nom));

      // Convertissez l'ensemble en tableau pour l'affichage
      setMatchingMaintenances([...uniqueNames]);
    } else {
      // Réinitialisez la liste si le champ de recherche est vide
      setMatchingMaintenances([]);
    }
  }, [searchTerm, allMaintenances]);

  const showMessagePopup = (message) => {
    alert(message);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newValue = name === "heures" ? parseInt(value) : value;

    if (name === "nom") {
      setSearchTerm(newValue);
    }

    setMaintenanceData({ ...maintenanceData, [name]: newValue });
  };

  const handleCancelClick = () => {
    setIsCreatingMaintenance(false);
    setMaintenanceData({
      heures: 0,
      date: new Date().toISOString(),
      nom: "",
      description: "",
      etat: "A_JOUR",
      maintenance: {
        id: "a76cab8f-d158-4af3-87ce-2aec72f2ad97",
      },
      machine: {
        id: machineId,
      },
    });
  };

  const checkDuplicateMaintenance = async (machineId, maintenanceName) => {
    try {
      const response = await axios.get(
        `${API_URL}/maintenancesmachine/machines/${machineId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const maintenances = response.data;
      return maintenances.some((maintenance) => maintenance.nom === maintenanceName);
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  

  const handleCreateMaintenance = async () => {
    const isDuplicate = await checkDuplicateMaintenance(
      machineId,
      maintenanceData.nom
    );
  
    if (isDuplicate) {
      setIsDuplicate(true)
      setTimeout(() => {
        setIsDuplicate(false);
      }, 3000);
    } else {
      axios
        .post(
          `${API_URL}/maintenancesmachine`,
          maintenanceData,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
            },
          }
        )
        .then((response) => {
          if (response.status === 401) {
            showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
            localStorage.clear();
            navigate.push("/login");
          } else {
            setIsCreatingMaintenance(false);
            setMaintenanceData({
              heures: 0,
              date: new Date().toISOString(),
              nom: "",
              description: "",
              etat: "A_JOUR",
              freq_intervention: 0,
              datemaintenance : "",
              maintenance: {
                id: "a76cab8f-d158-4af3-87ce-2aec72f2ad97",
              },
              machine: {
                id: machineId,
              },
            });
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  
  return (
    <div className="my-4">
      {isCreatingMaintenance ? (
        <div className="flex flex-col space-y-4 items-center">
          <div className="grid sm:grid-cols-2 gap-4 w-full">
            <input
              type="text"
              name="nom"
              value={maintenanceData.nom}
              onChange={handleInputChange}
              placeholder="Nom de la maintenance"
              className="border rounded px-2 py-1 text-lg"
            />
            <input
              type="text"
              name="description"
              value={maintenanceData.description}
              onChange={handleInputChange}
              placeholder="Description de la maintenance"
              className="border rounded px-2 py-1 text-lg"
            />
          </div>
          <div className="grid sm:grid-cols-2 gap-4 w-full relative">
            <input
              type="number"
              name="heures"
              value={maintenanceData.heures}
              onChange={handleInputChange}
              placeholder="Heures"
              className="border rounded px-2 py-1 text-lg"
            />
            {matchingMaintenances.length > 0 && (
              <ul className="suggestions-list absolute left-0 right-0 mt-10 bg-white border rounded">
                {matchingMaintenances.map((maintenanceName) => (
                  <li
                    key={maintenanceName}
                    onClick={() => {
                      // Recherchez la maintenance correspondante par son nom
                      const matchingMaintenance = allMaintenances.find(
                        (maintenance) => maintenance.nom === maintenanceName
                      );
                      if (matchingMaintenance) {
                        setMaintenanceData({
                          ...maintenanceData,
                          description: matchingMaintenance.description,
                          heures: matchingMaintenance.heures,
                          nom: matchingMaintenance.nom,
                        });
                      }
                      setMatchingMaintenances([]);
                      setSearchTerm("");
                    }}
                    className="cursor-pointer px-4 py-2 hover:bg-gray-200"
                  >
                    {maintenanceName}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="flex space-x-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white hover:text-white hover:font-bold px-4 py-2 rounded-md"
              onClick={handleCreateMaintenance}
            >
              Valider
            </button>
            <button
              onClick={handleCancelClick}
              className="bg-red-500 hover:bg-red-700 text-white rounded-md px-4 py-2"
            >
              Annuler
            </button>
          </div>
          {isDuplicate && (
  <div className="bg-red-500 text-white text-xl mt-2 p-2 rounded">
    Une maintenance avec ce nom existe déjà pour cette machine.
  </div>
)}
        </div>
      ) : (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-semibold hover:text-white hover:font-bold text-xs sm:text-2xl rounded-2xl py-4 px-6 mr-4 cursor-pointer 3xl:text-4xl 3xl:px-10 3xl:py-10"
          onClick={() => setIsCreatingMaintenance(true)}
        >
          Créer une maintenance exclusive
        </button>
      )}
    </div>
  );
}
