import React, { useState, useEffect } from 'react';

const EmailFrequencySelector = ({ maintenanceFrequency, setMaintenanceFrequency }) => {
  const [selectedFrequency, setSelectedFrequency] = useState(maintenanceFrequency);
  const [validationMessage, setValidationMessage] = useState(null);

  const handleChange = (event) => {
    setSelectedFrequency(event.target.value);
  };

  const handleValidation = () => {
    setMaintenanceFrequency(selectedFrequency);
    localStorage.setItem("maintenanceFrequency", selectedFrequency);
    setValidationMessage("Fréquence d'envoi mise à jour !");
  };

  useEffect(() => {
    if (validationMessage) {
      const timer = setTimeout(() => {
        setValidationMessage(null);
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [validationMessage]);

  return (
    <div className="p-4 border border-gray-200 rounded-md shadow-md text-center">
      
      <label className="block text-lg font-medium text-gray-700 mb-2">
        Fréquence d'envoi d'emails par défaut :
      </label>
      <select
        className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-gray-700 text-lg"
        value={selectedFrequency}
        onChange={handleChange}
      >
        <option value="daily">Tous les jours</option>
        <option value="weekly">Toutes les semaines</option>
        <option value="biweekly">Toutes les deux semaines</option>
        <option value="monthly">Tous les mois</option>
      </select>
      <button
        className="mt-4 bg-indigo-500 text-white font-medium text-lg py-2 px-4 rounded-md hover:bg-indigo-600"
        onClick={handleValidation}
      >
        Valider
      </button>
      {validationMessage && (
        <div className="bg-green-500 mt-4 text-white font-medium text-lg py-2 px-4 rounded-md mb-2">
          {validationMessage}
        </div>
      )}
    </div>
  );
};

export default EmailFrequencySelector;