import { configureStore } from '@reduxjs/toolkit';
import batteryLevelCacheReducer from './MachinesList/reducers';


const store = configureStore({
  reducer: {
    batteryLevelCache: batteryLevelCacheReducer,
  },
});

export default store;
