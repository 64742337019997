import "./admin.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import { API_URL } from "../constants";
import UserTable from "./UserTable";
import axios from 'axios';
import AddUserForm from "./AddUserForm";
import EditUserForm from "./EditUserForm";
import ChangePasswordForm from "./ChangePasswordForm";
import EditNameUser from "./EditNameUser";
import ChangeTypeForm from "./ChangeTypeForm";
import MachineAgency from "./MachineAgency";
import MachineType from "./MachineType";
import EmailFrequencySelector from "./EmailFrequencySelector";
import { UserTypes } from "../constants"

export default function Admin(props) {
  const user = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : props.user;
const token = user.auth_token;
  console.log(token)
  const userId = user.idu;
  const navigate = useNavigate();

  const initialMaintenanceFrequency = localStorage.getItem("maintenanceFrequency") || "daily";
  const [maintenanceFrequency, setMaintenanceFrequency] = useState(initialMaintenanceFrequency);

  const [users, setUsers] = useState([{ _id: "", email: "" }]);
  const [newuser, setNewser] = useState([{ mail: "", mdp: "" }]);
  const [id, setId] = useState();
  const [mail, setMail] = useState();

  const [messagemail, setMessageMail] = useState();
  const [message, setMessage] = useState();
  const [messageSupp, setMessageSupp] = useState();
  const [messageMoteur, setMessageMoteur] = useState();
  const [messageMoteurErreur, setMessageMoteurErreur] = useState();
  const [moteurs, setMoteurs] = useState([]);
  const userType = JSON.parse(localStorage.getItem("userType"));



  const [showMoteurList, setShowMoteurList] = useState(true);

  const showMessagePopup = (message) => {
    alert(message);
  };

  const clearMessages = () => {
    setTimeout(() => {
      setMessageMail(null);
      setMessage(null);
      setMessageSupp(null);
      setMessageMoteur(null);
      setMessageMoteurErreur(null);
    }, 5000);
  };

  const handleUserSelection = (userId) => {
    setId(userId);
  };

  useEffect(() => {
    clearMessages();
  }, [messagemail, message, messageSupp, messageMoteur, messageMoteurErreur]);

  /*
  Met à jour le message à chaque fois qu'on sélectionne un nouvel id
  */
  useEffect(() => {
    setMessage();
    setNewser({ [mail]: "" });
  }, [id]);

  /*
  Permet de récupérer la liste des utilisateurs enregistrés dans la base de données
  */
  useEffect(() => {
    axios.get(`${API_URL}/users`, {
      params: {
        limit: 20,
      },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(response => {
      setUsers(response.data.data);
    })
    .catch(error => {
      // Gestion des erreurs
    });
  }, [token]);
  
  

  function rafraichir() {
    axios.get(`${API_URL}/users`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(response => {
        setUsers(response.data.data);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
          localStorage.clear();
          navigate('/login');
        } else {
          console.log(error.message);
        }
      });
  }

  function suppr(id_utilisateur, userData) {
    axios.delete(`${API_URL}/users/${id_utilisateur}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      data: userData // Utilisez la propriété "data" pour envoyer les données dans le corps de la requête
    })
      .then(response => {
        setMessageSupp(response.data.message);
        rafraichir();
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
          localStorage.clear();
          navigate.push("/login");
        } else {
          console.log(error.message);
        }
      });
  }
  
  
  return (
    <div className="min-h-screen flex flex-col text-base sm:text-3xl">
      <Header user={user} />
      <div className="flex flex-auto h-full w-full items-center justify-center p-4">
        <div className="border-4 border-loginBordure rounded-2xl w-full text-xs sm:text-base sm:w-auto flex flex-col-reverse lg:flex-row p-6">
          <div id="flex flex-col items-center">
            {userType === UserTypes.CHEFAG ? (
              <h2 className="text-center text-loginBordure mb-6 text-lg sm:text-xl xl:text-5xl font-bold">Ajout Utilisateurs</h2>
            ) : (
              <h2 className="text-center text-loginBordure mb-6 text-lg sm:text-xl xl:text-5xl font-bold">Gestion Utilisateurs</h2>
            )}
            {messageSupp && (
              <span id="flex justify-center mt-4" className="bg-green-500 rounded-md text-white mx-auto py-2 px-4 text-center mt-4 mb-4 text-xs flex items-center justify-center">
                {messageSupp}
              </span>
            )}
            {userType !== UserTypes.CHEFAG && (
              <UserTable
              users={users}
              deleteUser={suppr}
              setSelectedUserId={handleUserSelection}
              setSelectedUserEmail={setMail}
              rafraichir={rafraichir}
              token={token}
            />
            )}
          </div>
          <div className="flex flex-col p-8 ">
            <div className="flex flex-col border-4 border-loginBordure rounded-2xl p-6 mb-4">
              <AddUserForm rafraichir={rafraichir} token={token} />
            </div>
            {userType === UserTypes.SUPER || userType === UserTypes.ADMIN ? (
              <>
                <div className="flex flex-col border-4 border-loginBordure rounded-2xl p-6 mb-4">
                  <MachineAgency token={token} />
                </div>
                <div className="flex flex-col border-4 border-loginBordure rounded-2xl p-6 mb-4">
                  <MachineType token={token} />
                </div>
              </>
            ) : null}
            {userType === UserTypes.SUPER || userType === UserTypes.ADMIN || userType === UserTypes.CHEFAG || userType === UserTypes.CHEFAT ? (
            <div className="flex flex-col border-4 border-loginBordure rounded-2xl p-6 mb-4">
              <EmailFrequencySelector maintenanceFrequency={maintenanceFrequency} setMaintenanceFrequency={setMaintenanceFrequency}/>
            </div>
            ) : null}
            {id ? userType !== UserTypes.CHEFAG &&  (
              <div className="flex flex-col border-4 border-loginBordure rounded-2xl p-4 text-base lg:text-2xl mb-4">
                <span className="text-center text-loginBordure 3xl:text-3xl">{mail}</span>
                <EditNameUser id={id} rafraichir={rafraichir} token={token} />
                <EditUserForm id={id} rafraichir={rafraichir} token={token} />
                <ChangePasswordForm id={id} rafraichir={rafraichir} token={token} />
                <ChangeTypeForm id={id} rafraichir={rafraichir} token={token} />
              </div>
            ) : (
              <></>
            )}            
          </div>
        </div>
      </div>
    </div>
  );  
}
